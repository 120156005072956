import React, { useEffect, useState } from 'react'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import SendIcon from '@mui/icons-material/Send'
import { InputBase } from '@mui/material'
import clsx from 'clsx'

import { buttonEvents, getJuvoInfo, setComponentValue } from '../../../store'
import {
  InputStateProps,
  isDefined,
  isUndefined,
  sanitizeInputName,
} from '../../../utils'
import {
  AppRegistration,
  Command,
  TextInputModel,
  Warning,
} from '../../../types'

const InputCommand: React.FC<{
  comp: TextInputModel
  onCommand: (cmd: Command) => void
  onChange: (_: TextInputModel) => void
  appInfo: [Warning, AppRegistration]
  inputStateProps: InputStateProps
}> = ({ comp, onCommand, appInfo, onChange, inputStateProps }) => {
  const juvoInfo = getJuvoInfo('input.input-command', comp)
  const label = comp.text || ''
  const recommendation = comp.recommendation
  const { value, setValue, handleBlur, handlersFromServer } = inputStateProps
  const [queuedEvent, setQueuedEvent] = useState<boolean>() // Queues up an onClick even to be fired when the next component update happens
  const inputName = sanitizeInputName(label)
  const inputValue = isDefined(value)
    ? value
    : isDefined(recommendation)
      ? recommendation
      : ''

  const evAttr = buttonEvents(
    comp,
    { type: 'from-stablestate', fn: onCommand },
    false,
    () => undefined,
    appInfo[1],
    '',
  )

  useEffect(() => {
    if (queuedEvent && evAttr) {
      evAttr.onClick()
      setQueuedEvent(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comp])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (evAttr && event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      onChange(setComponentValue(comp)(value))
      setQueuedEvent(true)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '24px auto 0px auto',
        position: 'relative',
        alignItems: 'center',
      }}
    >
      <FormControl
        variant="standard"
        fullWidth
        className="juvo-input-control"
        {...juvoInfo}
        sx={{
          margin: '0 !important',
        }}
      >
        <InputBase
          {...handlersFromServer}
          type="text"
          size="small"
          multiline
          id={inputName}
          slotProps={{ input: { className: 'juvo-chat-input' } }}
          name={inputName}
          value={inputValue}
          placeholder={label}
          onChange={handleChange}
          onClick={() => undefined} // to override handlersFromServer onClick
          onBlur={handleBlur}
          className={clsx(
            'juvo-input-base',
            isDefined(recommendation) && isUndefined(value) && 'recommendation',
          )}
          onKeyDown={handleKeyDown}
          sx={{
            padding: '0',
            '&::after': {
              display: 'none',
              padding: '4px',
            },
          }}
          data-testid={comp.data_testid}
        />
      </FormControl>
      {evAttr && (
        <IconButton
          size="small"
          {...evAttr}
          sx={{
            position: 'absolute',
            right: '4px',
          }}
          data-testid={`${comp.data_testid}-send-button`}
        >
          <SendIcon sx={{ color: theme => theme.palette.primary.main }} />
        </IconButton>
      )}
    </Box>
  )
}

export default InputCommand
