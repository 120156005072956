import React from 'react'
import {
  CssBaseline,
  GlobalStyles,
  Theme,
  ThemeOptions,
  ThemeProvider,
} from '@mui/material'
import '@fontsource/montserrat/300.css'
import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/700.css'
import '@fontsource/ubuntu-mono/400.css'
import '@fontsource/ubuntu-mono/700.css'

import '@fortawesome/fontawesome-pro/css/all.min.css'
import '@fortawesome/fontawesome-pro/css/sharp-light.min.css'
import '@fortawesome/fontawesome-pro/css/sharp-regular.min.css'
import '@fortawesome/fontawesome-pro/css/sharp-solid.min.css'

import { createAppTheme } from './default-theme'
import './AppStyles.scss'

// This function exports the material theme color variables to our global css space as css variables.
// It can be extended if more colors are needed.
const setGlobalStyles = (theme: Theme & CustomTheme) => (
  <GlobalStyles
    styles={{
      ':root': {
        '--color-primary': theme.palette.primary.main,
        '--color-primary-light': theme.palette.primary.light,
        '--color-primary-dark': theme.palette.primary.dark,
        '--color-secondary': theme.palette.secondary.main,
        '--color-success': theme.palette.success.main,
        '--color-error': theme.palette.error.main,
        '--color-warning': theme.palette.warning.main,
        '--color-warning-light': theme.palette.warning.light,
        '--color-info': theme.palette.info.main,
        '--color-black': theme.palette.text.primary,
        '--color-white': '#FFFFFF',
        '--color-tertiary': theme.palette.tertiary
          ? theme.palette.tertiary.main
          : '',
        '--color-background': '#dfdfe1',
        '--header-height': '64px',
        '--header-height-mobile': '50px',
        '--drawer-width': '400px',
        '--color-hover': theme.palette.grey[500],
        '--color-border': theme.palette.grey[400],
      },
    }}
  />
)

export const AppStyles: React.FC<{
  customTheme?: ThemeOptions
  children: React.ReactNode
}> = ({ customTheme, children }) => {
  const theme = createAppTheme(customTheme)

  return (
    <ThemeProvider theme={theme}>
      {/* Applies the CSS reset to remove browser default styling. */}
      <CssBaseline />
      {setGlobalStyles(theme)}
      {children}
    </ThemeProvider>
  )
}

export type AppTheme = ThemeOptions & CustomTheme

export interface CustomTheme {
  palette: {
    tertiary?: {
      main: string
      light: string
      dark: string
    }
  }
}
