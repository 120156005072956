import {
  Box,
  Chip,
  alpha,
  Tooltip,
  useTheme,
  darken,
  Checkbox,
} from '@mui/material'
import React from 'react'
import clsx from 'clsx'

import { isDefined } from '../../../../utils/Undefined'
import { CompareData } from '../../../../types'
import { createSlug } from '../../../../utils'

const ValueChips: React.FC<{
  chips: CompareData<string | boolean>[]
  currentValue: string | boolean
  comparisonFunc: (chip: any, val: any) => boolean
  onClickEvent: (item: any) => void
  labelMutator?: (item: any) => any
}> = ({ chips, currentValue, comparisonFunc, onClickEvent, labelMutator }) => {
  const theme = useTheme()

  const getStyles = (matches: boolean) => {
    const color = matches ? theme.palette.success : theme.palette.error
    return {
      color: `${darken(color.dark, 0.2)}!important`,
      fontWeight: 500,
      borderRadius: '0',
      background: alpha(color.main, 0.18),
      '&:hover': {
        background: alpha(color.main, 0.25),
      },
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        flexGrow: '1',
        gap: '6px',
      }}
    >
      {chips.map((item, index) => {
        const val = item.value
        if (typeof val === 'string') {
          const chipValue = isDefined(labelMutator) ? labelMutator(val) : val
          return (
            <Tooltip arrow title={val} key={index}>
              <Chip
                label={chipValue}
                onClick={() => onClickEvent(val)}
                sx={{
                  ...getStyles(comparisonFunc(val, currentValue)),
                }}
                data-testid={`chip-compare-${createSlug(chipValue)}`}
              />
            </Tooltip>
          )
        } else {
          return (
            <Tooltip arrow title={val.toString()} key={index}>
              <Checkbox
                checked={val}
                className={clsx(
                  'boolean-chip',
                  comparisonFunc(val, currentValue) && 'boolean-chip-match',
                )}
                onClick={() => onClickEvent(val)}
                sx={{
                  marginRight: '6px',
                  padding: '6px !important',
                  ...getStyles(comparisonFunc(val, currentValue)),
                }}
                data-testid={`chip-compare-checkbox-${val}`}
              />
            </Tooltip>
          )
        }
      })}
    </Box>
  )
}

export default ValueChips
