import React, { useContext, createContext, useState } from 'react'

import { getJuvoConfig, getLocalFavorites, setLocalFavorites } from '../utils'
import { logWarn } from '../utils/Logger'

import { useSnackbar } from './SnackbarProvider'

export const FavoritesContext = createContext<FavoritesContextProps>({
  favorites: [],
  addFavorite: () => undefined,
  deleteFavorite: () => undefined,
})

export type FavoritesContextProps = {
  favorites: string[]
  addFavorite: (fave: string) => void
  deleteFavorite: (fave: string) => void
}

export const useFavorites = () => useContext(FavoritesContext)

const FavoritesProvider: React.FC<{ children: any }> = ({ children }) => {
  const { environment } = getJuvoConfig()
  const { queueMessage } = useSnackbar()
  const [favorites, setFavorites] = useState<string[]>(() => {
    return getLocalFavorites(environment)
  })

  const deleteFavorite = (deleteFavorite: string) => {
    try {
      const newFavorites = favorites.filter(
        favorite => favorite !== deleteFavorite,
      )
      setLocalFavorites(environment, newFavorites)
      setFavorites(newFavorites)
    } catch (_) {
      queueMessage({
        title: 'Could not delete favorite',
        subtitle: 'Please try again.',
        subType: 'failure',
      })
      logWarn('Could not delete favorite')
    }
  }

  const addFavorite = (newFave: string) => {
    try {
      const newFavorites = [...favorites, newFave]
      setLocalFavorites(environment, newFavorites)
      setFavorites(newFavorites)
    } catch (_) {
      setFavorites([])
    }
  }

  return (
    <FavoritesContext.Provider
      value={{ favorites, addFavorite, deleteFavorite }}
    >
      {children}
    </FavoritesContext.Provider>
  )
}

export default FavoritesProvider
