import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Icon from '@mui/material/Icon'
import Box from '@mui/material/Box'
import { LinearProgress } from '@mui/material'
import clsx from 'clsx'

import {
  AppId,
  AppRegistration,
  AppSkeleton,
  Warning,
  clearAppSkeleton,
} from '../../types'
import Favorite from '../../components/Favorite/Favorite'
import { getJuvoConfig, getLastPathSegment } from '../../utils'
import { useOnSmallScreen } from '../../hooks/useOnSmallScreen'
import ConnectionStatus from '../../components/ConnectionStatus/ConnectionStatus'
import { imGet, ImmutableMap } from '../../utils/ImmutableMap'

import HeaderButton from './HeaderButton'

const Header: React.FC<{
  apps: ImmutableMap<AppId, [Warning, AppRegistration]>
  onAppSkelChange: (appId: AppId) => (appsk: AppSkeleton) => void
  onAppRegChange: (appId: AppId) => (appsk: AppRegistration) => void
  toggleTestApp: () => void
  isTestApp: boolean
  isDisconnected: boolean
}> = ({ apps, onAppSkelChange, toggleTestApp, isTestApp, isDisconnected }) => {
  const location = useLocation()
  const onSmallScreen = useOnSmallScreen()
  const { headerConfig, feedbackEmail } = getJuvoConfig()

  const appReg = useMemo(() => {
    // HACK: We need to get last path segment, but we cannot use useParams, because header is outside the Route context.
    const appId = getLastPathSegment()
    const app = imGet(apps)(appId)
    if (app === undefined) {
      return null
    } else {
      const [, appReg] = app
      return appReg
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, apps])

  const showEdit = appReg && headerConfig.testApp && !onSmallScreen
  const showLink = appReg && headerConfig.docLink && appReg.app_doclink
  const showEraser = appReg && headerConfig.eraser

  return (
    <AppBar
      position="relative"
      className="header"
      sx={{
        boxShadow: 'none !important',
        background: style => style.palette.grey[900],
        paddingRight: {
          xs: '4px',
          sm: '16px',
        },
        paddingLeft: {
          xs: 'var(--header-height-mobile)',
          sm: 'var(--header-height)',
        },
      }}
    >
      <Box
        sx={{
          color: style => style.palette.common.white,
          height: {
            xs: 'var(--header-height-mobile)',
            sm: 'var(--header-height)',
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {headerConfig.menu && (
          <>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                minWidth: 0,
              }}
            >
              {appReg && (
                <>
                  <Icon
                    className={clsx(appReg.app_agent?.icon)}
                    sx={{
                      width: 'var(--header-height-mobile)',
                      height: 'var(--header-height-mobile)',
                      fontSize: '1.2rem',
                      padding: 0,
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: { xs: 'none', sm: 'flex' },
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Tooltip
                        arrow
                        title={
                          appReg.app_name +
                          (appReg.app_version ? ' ' + appReg.app_version : '')
                        }
                      >
                        <Typography
                          sx={{
                            marginLeft: { xs: '0.25rem', sm: '0rem' },
                            marginRight: { xs: '0.25rem', sm: '1rem' },
                            paddingLeft: { xs: '8px', sm: 0 },
                            fontSize: '1.1rem',
                            lineHeight: 'initial',
                            fontWeight: style =>
                              style.typography.fontWeightBold,
                            color: style => style.palette.common.white,
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                          data-testid="header-name"
                        >
                          {appReg.app_name}
                        </Typography>
                      </Tooltip>
                    </Box>
                    {appReg.app_version && (
                      <Typography
                        sx={{
                          display: { xs: 'none', sm: 'block' },
                          color: style => style.palette.grey[400],
                          fontWeight: 500,
                          fontSize: '0.85rem',
                          lineHeight: 'initial',
                        }}
                        data-testid="header-version"
                      >
                        v{appReg.app_version}
                      </Typography>
                    )}
                  </Box>
                </>
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                gap: { xs: '4px', sm: '12px' },
                color: style => style.palette.grey[300],
                'button:hover, a:hover': {
                  color: style => style.palette.primary.light,
                  h6: {
                    color: style => style.palette.primary.light,
                  },
                },
                hr: {
                  height: '1.6em',
                  borderColor: style => style.palette.grey[400],
                },
              }}
            >
              {showEdit && (
                <HeaderButton
                  action={toggleTestApp}
                  title={isTestApp ? 'Show application' : 'Edit application'}
                  icon={isTestApp ? 'fa-browser' : 'fa-file-pen'}
                />
              )}

              {showLink && (
                <HeaderButton
                  action={appReg.app_doclink ?? ''}
                  title={appReg.app_doclink ?? ''}
                  icon="fa-external-link"
                />
              )}
              {showEraser && (
                <HeaderButton
                  action={() =>
                    onAppSkelChange(appReg.app_id)(
                      clearAppSkeleton(appReg.app_skeleton),
                    )
                  }
                  title="Clear form data"
                  icon="fa-eraser"
                />
              )}
              {(showEdit || showLink || showEraser) && <hr />}
              <HeaderButton
                action={`mailto:${feedbackEmail}?subject=Feedback from ${
                  appReg
                    ? appReg.app_name + ' ' + appReg.app_version
                    : 'Juvo dashboard'
                }`}
                title="Send feedback"
                icon="fa-message-lines"
                extraProps={{
                  target: '_blank',
                  rel: 'noopener noreferrer',
                }}
              />
              {appReg && <Favorite aria-label="menu" appId={appReg.app_id} />}
              {headerConfig.connectionStatus && isDisconnected && (
                <ConnectionStatus />
              )}
            </Box>
          </>
        )}
        {isDisconnected && (
          <LinearProgress
            sx={{
              position: 'absolute',
              bottom: '0',
              left: '0',
              right: '0',
            }}
            color="error"
          />
        )}
      </Box>
    </AppBar>
  )
}

export default Header
