import React from 'react'

import SwitchYard from '../../juvo-component/SwitchYard/SwitchYard'
import {
  adjustStdComponentArgs,
  ComponentModel,
  isWhenCase,
  isWhenDefault,
  StdComponentArgs,
  WhenChildModel,
  WhenModel,
  whenSelection,
} from '../../../types'
import { containerSetChild, getJuvoInfo } from '../../../store'
import { isUndefined } from '../../../utils/Undefined'
import { logErr } from '../../../utils/Logger'

/**
 * Renders a user branching decision that comes from a paired component (e.g. CheckBoxField, SelectField, etc.)
 */
const When: React.FC<StdComponentArgs<WhenModel>> = props => {
  const { comp, onComponentChange } = props
  const children = comp.tchildren || []
  const whenBranch = whenSelection(comp)

  //whenBranch will be null if:
  //  * when component is added as new record in a list
  //  * backend returns msg data for union/ enum which value is not covered by the 'jf-when' in xml (no-exhaustive match in the app)
  //  * can be caused by earaser
  // this are the known cases I can think about now,  it is important to note that this appears to be a catch-all situation for
  // non-exhaustive data-UI matching (xml does not cover all union cases situations)
  const childIndex: number = children.findIndex((c: WhenChildModel) => {
    if (isWhenDefault(c)) {
      return isUndefined(whenBranch)
    } else if (isWhenCase(c)) {
      return c.when === whenBranch || c.when._inclusive_ === whenBranch
    } else {
      logErr(
        'Impossible, WhenChildModel needs to be isWhenDefault or isWhenCase',
        c,
      )
      return false
    }

    // return isUndefined(whenBranch)
    //   ? c.when_default === true
    //   : c.when === whenBranch || c.when._inclusive_ === whenBranch
  })

  if (childIndex == -1) {
    return <div className="when-no-selection" />
  } else {
    const childComp: ComponentModel | null = children[childIndex]
    if (childComp === null) {
      //addins sometimes do not provide all choices, especially with checkbox (nothing on false)
      //this needs to be supported as a valid case
      return (
        <div {...getJuvoInfo('When', comp)} className="when-mismatch"></div>
      )
    } else {
      const childProps = adjustStdComponentArgs(props, childComp, nc =>
        onComponentChange(containerSetChild(nc, childIndex, comp)),
      )

      return (
        <div {...getJuvoInfo('When', comp)} className="when-selection">
          <SwitchYard key={childIndex} {...childProps} />
        </div>
      )
    }
  }
}

export default When
