import React from 'react'
import { Box, Button, Icon, Tooltip, alpha } from '@mui/material'

import { FileDownloadsModel } from '../../../types'
import { createSlug, getDownloadIconFromFileName } from '../../../utils/Common'

export const FileDownload: React.FC<{
  component: FileDownloadsModel
}> = ({ component }) => {
  const links = component.links ?? []

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '10px',
        gap: '8px',
      }}
    >
      {links.map((link, index) => (
        <Button
          key={index}
          variant="outlined"
          href={link.url}
          download={link.name}
          target="_blank"
          rel="noopener noreferrer"
          startIcon={
            <Icon
              className={`fa ${getDownloadIconFromFileName(link.name)}`}
              sx={{ fontSize: '0.9rem!important' }}
            />
          }
          sx={{
            margin: '0',
            borderRadius: '4px',
            fontWeight: 500,
            background: alpha('#fff', 0.85),
            '&:hover': {
              background: theme => theme.palette.grey[300],
            },
            border: '1px solid var(--color-border)',
            fontSize: '0.9em',
          }}
          data-testid={`btn-download-${createSlug(link.name)}`}
        >
          <Tooltip arrow title={link.name} enterDelay={800}>
            <Box
              component="span"
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {link.name}
            </Box>
          </Tooltip>
        </Button>
      ))}
    </Box>
  )
}
