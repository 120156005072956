import { Dispatch, SetStateAction, useState } from 'react'

export const useHistoryState = <T>(initial: T) => {
  const [apps, setMyState] = useState<T>(initial)
  const [prevState, setPrevState] = useState<T>(initial)

  const setApps: Dispatch<SetStateAction<T>> = (
    value: T | ((prev: T) => T),
  ) => {
    setMyState(prev => {
      setPrevState(prev)
      return value instanceof Function ? value(prev) : value
    })
  }

  return { apps, prevState, setApps }
}
