import React, { useEffect, useState } from 'react'

import {
  AppRegistration,
  ComponentModel,
  ModalModel,
  ResultSubType,
  User,
  Warning,
} from '../../types/Model'
import { CustomComponentHandler } from '../../store'
import { ResultChildrenMapper } from '../ResultChildrenMapper/ResultChildrenMapper'
import { getAlertSeverityBySubType } from '../../utils/Notifications'

import { JuvoDialog } from './JuvoDialog'

const JuvoResultModal: React.FC<{
  component: ModalModel
  customReactComps: CustomComponentHandler
  appInfo: [Warning, AppRegistration]
  user: User
  subType: ResultSubType
}> = ({ component, customReactComps, appInfo, user, subType }) => {
  const children: ComponentModel[] = component.tchildren || []
  const [open, setOpen] = useState(true)

  useEffect(() => {
    setOpen(true)
  }, [component])

  return (
    <JuvoDialog
      title={component.title}
      severity={getAlertSeverityBySubType(subType)}
      open={open}
      handleClose={() => setOpen(false)}
    >
      <ResultChildrenMapper
        customReactComps={customReactComps}
        appInfo={appInfo}
        user={user}
      >
        {children}
      </ResultChildrenMapper>
    </JuvoDialog>
  )
}

export default JuvoResultModal
