import React from 'react'
import MuiButton from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Icon from '@mui/material/Icon'

import { AppRegistration, ButtonModel, Command } from '../../../types'
import { buttonEvents, getJuvoInfo } from '../../../store'
import { ValidationError } from '../../../store/Validation'
import { useUploading } from '../../../providers/UploadingProvider'

const Button: React.FC<{
  comp: ButtonModel
  onCommand: (cmd: Command) => void
  setValidationErrs: (_: ValidationError[]) => void
  app: AppRegistration
  handlerValidation?: (value: boolean) => void
  compId: string
}> = ({
  comp,
  onCommand,
  setValidationErrs,
  app,
  handlerValidation,
  compId,
}) => {
  const { isUploading } = useUploading()

  const performValidations =
    comp.input_actions.find(ia => ia.event === 'Click')?.perform_validation ||
    false
  const evAttr: any = buttonEvents(
    comp,
    { type: 'from-stablestate', fn: onCommand },
    performValidations,
    setValidationErrs,
    app,
    compId,
    performValidations ? handlerValidation : undefined,
  )

  if (evAttr) {
    return (
      <MuiButton
        variant={comp.variant ?? 'contained'}
        {...getJuvoInfo('Button', comp)}
        {...evAttr}
        endIcon={comp.icon && <Icon className={comp.icon} />}
        data-testid={comp.data_testid}
        disabled={isUploading}
      >
        {comp.text || 'Unknown'}
      </MuiButton>
    )
  } else {
    return (
      <Typography {...getJuvoInfo('Button.invalid', comp)} variant="body1">
        <strong>Invalid button event configuration</strong>
      </Typography>
    )
  }
}

export default Button
