import { BlockBlobClient } from '@azure/storage-blob'

import { FetchError, betterFetchJson } from '../utils/Fetch'
import { getJuvoConfig } from '../utils/JuvoConfig'
import { logDebug } from '../utils/Logger'
import { Either } from '../utils/Common'
import { base64ToArrayBuffer } from '../store/File'

export interface TokenFetchResponse {
  '@': 'SasToken'
  token: string
  url: string
}

//TODO probably not needed
export const getFileToken = async (
  guid: string,
  fileName: string,
  app_id: string,
): Promise<Either<FetchError, TokenFetchResponse>> => {
  const { platformHttpUrl } = getJuvoConfig()

  const res = await betterFetchJson<TokenFetchResponse>(
    `${platformHttpUrl}/sas-token-for-uploads/${guid}/${app_id}/${fileName}`,
    {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
      },
    },
  )

  logDebug({ tokenRes: res })
  return res
}

export const uploadFile = (url: string, token: string, file: string) => {
  const blobSasUrl = `${url}?${token}`
  const blobClient = new BlockBlobClient(blobSasUrl)
  return blobClient.uploadData(base64ToArrayBuffer(file))
}
