import React from 'react'
import Icon from '@mui/material/Icon'
import Tooltip from '@mui/material/Tooltip'
import { Box } from '@mui/material'

const ConnectionStatus: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Tooltip arrow title="Not connected">
        <Icon
          className="fa fa-plug"
          fontSize="medium"
          sx={{
            color: theme => theme.palette.error.main,
          }}
        />
      </Tooltip>
    </Box>
  )
}

export default ConnectionStatus
