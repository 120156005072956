import { Box, Typography, Icon } from '@mui/material'
import React from 'react'

import RocketFlight from '../../Loading/RocketFlight'

export const UploadLoadingIndicator: React.FC<{
  exteriorLoading: boolean
  interiorLoading: boolean
}> = ({ exteriorLoading, interiorLoading }) => {
  if (exteriorLoading) {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <RocketFlight small />
        </Box>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '1.2rem',
          }}
        >
          Uploading...
        </Typography>
      </>
    )
  } else if (interiorLoading) {
    return (
      <>
        <Icon
          className="fa-duotone fa-spinner-third fa-spin"
          fontSize="large"
          sx={{
            color: theme => theme.palette.text.secondary,
            fontSize: '4rem',
            padding: 0,
            boxSizing: 'unset',
            width: 'unset',
            height: 'unset',
          }}
        />
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '1.2rem',
          }}
        >
          Adding file...
        </Typography>
      </>
    )
  } else {
    // Shouldn't get here
    throw new Error('Upload loading indicator, logical error')
  }
}
