import { TabContext, TabList } from '@mui/lab'
import { Tab, useTheme } from '@mui/material'
import React, { useEffect } from 'react'

import { setComponentValue } from '../../../store'
import { Command, RatingModel, TabSelectModel } from '../../../types'
import { createSlug, getJuvoProps, isDefined } from '../../../utils'

const TabSelect: React.FC<{
  comp: TabSelectModel
  onCommand: (cmd: Command) => void
  onChange: (_: RatingModel) => void
}> = ({ comp, onCommand, onChange }) => {
  const theme = useTheme()

  const handleChange = (newValue: string) => {
    onChange(setComponentValue(comp)(newValue))
  }

  const { eventHandlers, value } = getJuvoProps(
    'Rating',
    comp,
    onCommand,
    handleChange,
  )

  const defv = comp.options.length > 0 ? comp.options[0] : 'invalid'
  const val =
    isDefined(value) && value !== '' ? value : comp.recommendation || defv

  // This is to make sure the value is set on the very first render
  useEffect(() => {
    onChange(setComponentValue(comp)(val))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TabContext value={val}>
      <TabList
        variant="scrollable"
        onChange={(_: any, newValue: string) =>
          eventHandlers.onChange(newValue)
        }
        aria-label="tabs"
        sx={{
          zIndex: theme => theme.zIndex.appBar,
          '.MuiTabs-scrollButtons': {
            display: { xs: 'flex' },
            width: '20px',
          },
          borderBottom: `1px solid ${theme.palette.grey[400]}`,
          marginBottom: '16px',
        }}
      >
        {comp.options.map((label, idx) => (
          <Tab
            label={label}
            value={label}
            key={idx}
            className="juvo-tab"
            data-testid={`tab-${createSlug(label)}-${comp.data_testid}`}
          />
        ))}
      </TabList>
    </TabContext>
  )
}

export default TabSelect
