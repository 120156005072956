import React, { useMemo } from 'react'
import MuiButton from '@mui/material/Button'
import { Link, SxProps, Theme } from '@mui/material'

import { LinkButtonModel } from '../../../types'
import { getJuvoInfo } from '../../../store'
import { sanitizeUrl } from '../../../utils'

const LinkButton: React.FC<{ comp: LinkButtonModel }> = ({ comp }) => {
  const styles: SxProps<Theme> = [
    {
      display: 'inline-block',
      marginTop: '2rem',
      marginBottom: '1rem !important',
    },
    comp.inlined === 'right'
      ? {
          float: 'right',
          '&:not(:last-of-type)': { marginLeft: '3rem' },
        }
      : {
          '&:not(:last-of-type)': { marginRight: '3rem' },
        },
    comp.variant === 'contained' && {
      backgroundColor: (theme: Theme) => theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: (theme: Theme) => theme.palette.secondary.dark,
      },
    },
  ]

  const url = useMemo((): string => {
    if (comp.value) {
      return sanitizeUrl(comp.value)
    } else if (comp.recommendation) {
      return sanitizeUrl(comp.recommendation)
    } else if (comp.href) {
      return sanitizeUrl(comp.href)
    } else {
      return '#'
    }
  }, [comp])

  return comp.variant === 'link' ? (
    <Link
      href={url}
      {...getJuvoInfo('url', comp)}
      target="_blank"
      rel="noopener noreferrer"
      sx={styles}
      data-testid={comp.data_testid}
    >
      {comp.text || 'Unknown'}
    </Link>
  ) : (
    <MuiButton
      variant={comp.variant ?? 'contained'}
      {...getJuvoInfo('url', comp)}
      component="a"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      sx={styles}
    >
      {comp.text || 'Unknown'}
    </MuiButton>
  )
}

export default LinkButton
