import React, { SyntheticEvent } from 'react'
import MuiAccordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import MuiButton from '@mui/material/Button'
import {
  alpha,
  Box,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  Typography,
} from '@mui/material'

import {
  AppId,
  AppSkeleton,
  ComponentModel,
  isAppSkeleton,
  isJuvoMessage,
  JuvoMessage,
  Message,
} from '../../types'
import './Test.scss'
import { isDefined } from '../../utils/Undefined'
import { logDebug, logWarn } from '../../utils/Logger'

const GenericAccordion: React.FC<{
  summary: string
  children: React.ReactElement
  expanded?: boolean
  onChange?: (event: SyntheticEvent<any>, expanded: boolean) => void
}> = ({ summary, children, expanded, onChange }) => {
  return (
    <MuiAccordion
      expanded={expanded}
      onChange={onChange}
      sx={{
        boxShadow: 'none !important',
        margin: '16px 0',
        border: theme => `1px solid ${theme.palette.secondary.dark}`,
        '.MuiAccordionSummary-root.Mui-expanded': {
          minHeight: '48px',
          borderBottom: theme => `1px solid ${theme.palette.secondary.dark}`,
        },
        background: theme => alpha(theme.palette.secondary.main, 0.5),
        '&:before': {
          height: '0 !important',
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <IconButton
            className="fa fa-chevron-up"
            size="small"
            aria-label="open accordion"
          />
        }
        sx={{
          flexDirection: 'row-reverse',
          background: theme => theme.palette.secondary.main,
          '.Mui-expanded': {
            margin: '12px 0 !important',
          },
          '.MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        <Typography
          variant={'subtitle2'}
          sx={{
            marginLeft: '16px',
            letterSpacing: '0.15px',
            lineHeight: '28px',
          }}
        >
          {summary}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="accordion-details">
        {children}
      </AccordionDetails>
    </MuiAccordion>
  )
}

const TestEff: React.FC<{
  onOutMsg: (c: ComponentModel) => (msg: Message) => Promise<void>
  onInMsg: (msg: Message) => void
  outTestMsgTxt: string
  setOutTestMsgText: (msg: string) => void
  inTestMsgTxt: string
  setInTestMsgText: (msg: string) => void
  testSkelAppId: string | null
  setTestSkelAppId: (_: string | null) => void
  testSkelTxt: string
  setTestSkelTxt: (msg: string) => void
  onAppDataChange: (appId: AppId) => (appsk: AppSkeleton) => void
  closeTestApp: () => void
}> = ({
  onOutMsg,
  onInMsg,
  outTestMsgTxt,
  setOutTestMsgText,
  inTestMsgTxt,
  setInTestMsgText,
  testSkelAppId,
  setTestSkelAppId,
  testSkelTxt,
  setTestSkelTxt,
  onAppDataChange,
  closeTestApp,
}) => {
  // TODO move these out as outTstMsgTxt and inTxtMsgTxt
  // and make this pure
  const [inMsgExpanded, setInMsgExpanded] = React.useState<boolean | null>(null)

  const computedExpandedInMsg =
    inMsgExpanded === null ? '' !== inTestMsgTxt : inMsgExpanded

  const getMsg = (txt: string): Message => {
    const out: JuvoMessage = JSON.parse(txt)
    if (isJuvoMessage(out)) {
      logDebug({ testMsg: out })
      return { type: 'msg', payload: out }
    } else {
      logDebug({ notAJuvoMsg: out })
      return null
    }
  }

  const setAppSkel = (): void => {
    const appskel = JSON.parse(testSkelTxt)
    if (isAppSkeleton(appskel)) {
      if (isDefined(testSkelAppId)) {
        onAppDataChange(testSkelAppId)(appskel)
        logDebug({
          msg: `updated app skel for ${testSkelAppId}`,
          data: appskel,
        })
      } else {
        logWarn('Need appId')
      }
    } else {
      logWarn('Not a valid app skeleton')
    }
  }

  return (
    <>
      <GenericAccordion summary="Change App Skeleton">
        <>
          <FormControl className="juvo-input-control full-width">
            <InputLabel shrink className="juvo-input-label">
              AppId
            </InputLabel>
            <InputBase
              type="text"
              value={testSkelAppId}
              onChange={e => setTestSkelAppId(e.target.value)}
              className="juvo-no-validation"
            />
          </FormControl>

          <FormControl className="juvo-input-control full-width">
            <InputLabel shrink className="juvo-input-label">
              App Skeleton
            </InputLabel>
            <InputBase
              type="text"
              multiline
              minRows={10}
              value={testSkelTxt}
              onChange={e => setTestSkelTxt(e.target.value)}
              className="juvo-no-validation"
            />
          </FormControl>
          <Box className="button-container">
            <MuiButton variant="outlined" onClick={() => setOutTestMsgText('')}>
              Clear
            </MuiButton>
            <MuiButton variant="contained" onClick={() => setAppSkel()}>
              Update Skeleton
            </MuiButton>
          </Box>
        </>
      </GenericAccordion>
      <GenericAccordion summary="Send Out Message">
        <>
          <FormControl className="juvo-input-control full-width">
            <InputLabel shrink className="juvo-input-label">
              Websocket Out Message
            </InputLabel>
            <InputBase
              type="text"
              multiline
              minRows={10}
              value={outTestMsgTxt}
              onChange={e => setOutTestMsgText(e.target.value)}
              className="juvo-no-validation"
            />
          </FormControl>
          <Box className="button-container">
            <MuiButton variant="outlined" onClick={() => setOutTestMsgText('')}>
              Clear
            </MuiButton>
            <MuiButton
              variant="contained"
              onClick={() => onOutMsg(null)(getMsg(outTestMsgTxt))}
            >
              Send Test Message
            </MuiButton>
          </Box>
        </>
      </GenericAccordion>
      <GenericAccordion
        summary="Mock In Message"
        expanded={computedExpandedInMsg}
        onChange={(e, isExpanded) => {
          setInMsgExpanded(isExpanded)
        }}
      >
        <>
          <FormControl className="juvo-input-control full-width">
            <InputLabel shrink className="juvo-input-label">
              Websocket In Message
            </InputLabel>
            <InputBase
              type="text"
              multiline
              minRows={10}
              value={inTestMsgTxt}
              onChange={e => setInTestMsgText(e.target.value)}
              className="juvo-no-validation"
            />
          </FormControl>
          <Box className="button-container">
            <MuiButton variant="outlined" onClick={() => setInTestMsgText('')}>
              Clear
            </MuiButton>
            <MuiButton
              variant="contained"
              onClick={() => onInMsg(getMsg(inTestMsgTxt))}
            >
              Mock In Message
            </MuiButton>
          </Box>
        </>
      </GenericAccordion>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <MuiButton variant="outlined" onClick={closeTestApp}>
          Return to App
        </MuiButton>
      </Box>
    </>
  )
}

export default TestEff
