import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import MuiButton from '@mui/material/Button'
import { Icon, IconButton, ListItem, Typography } from '@mui/material'

import { getJuvoInfo } from '../../../store'
import { ClaimListModel, ClaimView, StdComponentArgs } from '../../../types'
import Claim, { ClaimsTxtChanged, DoneWithChanges } from '../ClaimList/Claim'
import { isUndefined } from '../../../utils/Undefined'
import { logDebug } from '../../../utils/Logger'

import {
  changeClaimView,
  claimsFromView,
  compress,
  format,
  uncondenseClaim,
} from './utils'

const ClaimList: React.FC<StdComponentArgs<ClaimListModel>> = props => {
  const { comp, onComponentChange } = props
  const [internalComp, setInternalComp] = useState(props.comp)

  //TODO this lazily initializes the compressed ClaimsView[]
  // a better approach would be to move this to handling of incoming server messages,
  // however, currently we do not have a placeholder to idiosyncratically handle
  // results of platform.new http request that returns all the skeletons.
  // Doing this will make testing a bit complicated (test app would need to be used separately
  // with a skeleton first, and then a message with data would need to mocked separately).
  // For now this should do it.
  useEffect(() => {
    if (isUndefined(internalComp.internal_condensed_claims)) {
      const initializedClaims = initializeClaims(comp)
      onComponentChange(initializedClaims)
      setInternalComp(initializedClaims)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comp, internalComp])

  // Return something of a dummy to just get it running
  const condensed_claims: ClaimView[] = comp.internal_condensed_claims ?? []

  const addElem = () => {
    const newCondensedChildren = [
      ...condensed_claims,
      { ...createEmptyCondensedClaimModel() },
    ]
    const updatedComp = {
      ...internalComp,
      internal_condensed_claims: newCondensedChildren,
    }
    setInternalComp(updatedComp)
    onComponentChange(updatedComp)
  }

  const removeElem = (index: number) => {
    const newCondensedChildren = condensed_claims.filter(
      (_, ix) => ix !== index,
    )
    const newChildren = claimsFromView(newCondensedChildren, comp.claims)
    logDebug('new claims: ', newChildren)
    const updatedComp = {
      ...internalComp,
      claims: newChildren,
      internal_condensed_claims: newCondensedChildren,
    }
    setInternalComp(updatedComp)
    onComponentChange(updatedComp)
  }

  return (
    <div>
      <div {...getJuvoInfo('ClaimList', comp)} className="claim-list-container">
        <Typography variant="body1">Claim Information</Typography>
        {condensed_claims.map((cp, index) => {
          const onClaimChange = (
            nc: ClaimsTxtChanged | DoneWithChanges,
          ): void => {
            switch (nc.changeType) {
              case 'done':
                return onComponentChange(
                  uncondenseClaim(nc.payload, index, comp),
                )
              case 'claimsTxtChange':
                return onComponentChange(
                  changeClaimView(nc.payload, index, comp),
                )
            }
          }
          //This is not really needed
          // const childProps: StdComponentArgs<any> =
          //   adjustStdComponentArgs(props, cp, onClaimChange)
          return (
            <ListItem
              key={index}
              component={Paper}
              elevation={4}
              sx={{
                position: 'relative',
                marginTop: '1rem',
              }}
            >
              <Box
                sx={{
                  display: 'inline-block',
                }}
              >
                <IconButton
                  className="delete-list-item-btn fa fa-trash"
                  juvo-action="list-delete"
                  color="inherit"
                  size="small"
                  sx={{
                    position: 'absolute',
                    top: '0.5rem',
                    right: '0.5rem',
                    fontSize: '1rem',
                    color: style => style.palette.primary.main,
                    '&:hover': {
                      color: style => style.palette.error.main,
                    },
                  }}
                  onClick={() => removeElem(index)}
                />
                <Claim
                  key={index}
                  comp={cp}
                  onChange={onClaimChange}
                  onCommand={props.onCommand}
                />
              </Box>
            </ListItem>
          )
        })}
      </div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          borderBottom: '1px solid #dddcdc',
        }}
      >
        <MuiButton
          variant="text"
          startIcon={<Icon className="fa fa-plus" />}
          juvo-action="list-add"
          onClick={addElem}
        >
          Add Claim
        </MuiButton>
      </Box>
    </div>
  )
}

export default ClaimList

export const createEmptyCondensedClaimModel = (): ClaimView => {
  return { claims: '', status: '', isIndependent: null }
}

//TODO this and basic functions that operate on ClaimListModel
// should be moved next to where the ClaimListModel is defined (Model.ts or a new module (Model/Claim.ts)
export const initializeClaims = (comp: ClaimListModel): ClaimListModel => {
  const claims = comp.claims || []
  const claimMapping = compress(claims)
  const condensedClaims = format(claimMapping)
  return { ...comp, internal_condensed_claims: condensedClaims }
}
