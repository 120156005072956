import React from 'react'
import { NavLink } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import { Box } from '@mui/material'

import { getSuggestedApps } from '../../store'
import { ImmutableMap } from '../../utils/ImmutableMap'
import { AppId, AppRegistration, Warning } from '../../types'
import { Nullable } from '../../utils'

import homeLogo from './home.svg'

const Home: React.FC<{
  apps: ImmutableMap<AppId, [Warning, AppRegistration]>
  suggestions: Nullable<AppId[]>
  onAllAppsClicked: () => void
}> = ({ apps, suggestions, onAllAppsClicked }) => {
  const suggestedApps = React.useMemo(() => {
    return getSuggestedApps(apps, suggestions)
  }, [apps, suggestions])

  return (
    <Box
      className="dashboard"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        color: 'black',
      }}
    >
      <Box
        sx={{
          padding: '36px 0',
          marginBottom: '8px',
          '& img': {
            width: { xs: '300px', md: '400px' },
          },
        }}
      >
        <img src={homeLogo} alt="recommended applications" />
      </Box>
      {suggestedApps.length > 0 ? (
        <Typography
          variant="subtitle2"
          sx={{
            marginTop: '40px',
            alignSelf: 'center',
            marginRight: '12px',
            textAlign: 'center',
            color: theme => theme.palette.primary.light,
            width: { xs: 'auto', md: '680px' },
          }}
        >
          Favorite apps
        </Typography>
      ) : (
        <h4>
          {"You don't have any favorites yet. Click 'All Apps' to browse."}
        </h4>
      )}
      <Box
        sx={{
          width: { xs: '100%', md: '800px' },
          maxWidth: '100%',
          margin: '16px 0',
          padding: { xs: '0 16px', sm: '0 64px', md: 0 },
          display: { xs: 'block', md: 'grid' },
          gridTemplateColumns: '1fr 1fr',
          gap: '8px',
        }}
      >
        {suggestedApps.map(app => (
          <Button
            component={NavLink}
            to={`apps/${app.app_id}`}
            className="recommended-app"
            key={app.app_id}
            sx={{
              width: '100%',
              textTransform: 'capitalize',
              display: 'flex',
              justifyContent: 'left',
              transition: 'all 0.22s ease',
              overflow: 'hidden',
              marginBottom: { xs: '8px', md: 0 },
              padding: '20px 64px 20px 20px',
              gap: '8px',
              background: style => style.palette.secondary.main,
              '&:hover': {
                background: style => style.palette.primary.dark,
                '*': {
                  color: 'white',
                },
                '.recommended-app-arrow': {
                  transform: 'translateX(0)',
                },
              },
            }}
          >
            <Icon
              className={app.app_agent?.icon}
              sx={{
                color: theme => theme.palette.text.primary,
                transition: 'all 0.22s ease',
              }}
            />
            <Typography
              sx={{
                fontWeight: style => style.typography.fontWeightMedium,
                transition: 'all 0.22s ease',
              }}
            >
              {app.app_name}
            </Typography>
            <Box
              className="recommended-app-arrow"
              sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                display: 'flex',
                alignItems: 'center',
                transform: 'translateX(100%)',
                overflow: 'hidden',
                transition: 'transform 0.22s ease',
                padding: '0 20px',
                background: style => style.palette.secondary.main,
              }}
            >
              <Icon
                className="fa-sharp fa-solid fa-arrow-right"
                sx={{
                  color: style => `${style.palette.primary.main}!important`,
                }}
              ></Icon>
            </Box>
          </Button>
        ))}
      </Box>
      <Button
        variant="outlined"
        color="primary"
        onClick={onAllAppsClicked}
        sx={{
          marginTop: '16px',
          textTransform: 'capitalize',
        }}
        className="all-apps-btn"
      >
        {suggestedApps.length > 0 ? 'All Apps' : 'See All Apps'}
      </Button>
    </Box>
  )
}

export default Home
