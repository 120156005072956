import React from 'react'

interface GenericProviderProps {
  providers: Array<any>
  children: React.ReactNode
}

export const provider = (
  provider: React.JSXElementConstructor<any>,
  props = {},
) => [provider, props]

export const CombineProviders = (props: GenericProviderProps) => {
  const { providers = [], children } = props

  return (
    <>
      {providers.reduceRight((acc, Comps) => {
        const [Provider, props] = Comps
        return <Provider {...props}>{acc}</Provider>
      }, children)}
    </>
  )
}
