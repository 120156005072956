/**
 * Command components are not UI components, they contain command instructions.
 * These are used outside of React, office integration will use them.
 */
import { AppRegistration, Command, ComponentModel, Warning } from '../types'
import { pureAsync } from '../utils/Common'
import { getJuvoConfig } from '../utils/JuvoConfig'
import { logDebug, logWarn } from '../utils/Logger'
import { Nullable } from '../utils/Undefined'

type CmdComponent = any

//InMsg from server that is a command
export type CmdMessage = {
  command: Command
  components?: Nullable<CmdComponent>
  req_stamp?: Nullable<string>
}

export const notImplementedHandler: CmdHandler = (cmd: CmdMessage) => {
  logWarn({
    officeIntegration: 'Command Handler not implemented',
    cmMsg: cmd,
  })
  const dta = cmd.command ? { command: cmd.command } : cmd
  return { type: 'warning', warn: 'Command Handler not implemented', dta: dta }
}

// TODO: find a better name for value
export const isCmdMessage = (value: any): value is CmdMessage => {
  return value.command && value.command['@'] !== 'display'
}

export type CmdHandler = (cmd: CmdMessage) => Warning

/*
 ---- types enabling Custom Components -----

 Outlook, Word, even web can define Custom components. 
 Currently model state updates are not customizable per platform type but
 this allows to do UI customizations.

 These override the SwitchBoard on top level, see 
    SwitchYard.tsx component
    Example overrides in src/components/custom-example

*/

export type CustomComponentProps = {
  onComponentChange: (newComponent: ComponentModel) => void
  onCommand: (cmd: Command) => void
  appInfo: [Warning, AppRegistration]
}

export type CustomComponent = (cc: CustomComponentProps) => JSX.Element

/**
 * Given component model, return CustomComponent if exists or null if it does not
 */
export type CustomComponentHandler = (
  comp: ComponentModel,
) => CustomComponent | null

export const noCustomComponents: CustomComponentHandler = () => null

export type LoginResult =
  | {
      type: 'cookie'
      payload: null
    }
  | {
      type: 'user'
      payload: string
    }
  | {
      type: 'error'
      payload: string
    }
export type LoginHandler = () => Promise<LoginResult>

export const webLoginHandler: LoginHandler = async () => {
  logDebug('Redirecting to login page')
  const { platformHttpUrl } = getJuvoConfig()
  window.location.href = `${platformHttpUrl}/login`
  return pureAsync({ type: 'cookie', payload: null })
}
