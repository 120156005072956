import React, { CSSProperties, useEffect, useState } from 'react'
import MuiButton from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import MuiList from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Box from '@mui/material/Box'
import { InputLabel, Theme, useTheme } from '@mui/material'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'

import SwitchYard from '../../juvo-component/SwitchYard/SwitchYard'
import {
  adjustStdComponentArgs,
  ComponentModel,
  ContentHash,
  ContentHashedComponent,
  inputActionCmd,
  ListModel,
  StdComponentArgs,
} from '../../../types'
import {
  addIdsToCompIfMissing,
  containerSetChild,
  getJuvoInfo,
  userAcceptRecommended,
  userDeleteRecommended,
} from '../../../store'
import { createSlug } from '../../../utils'

const LIST_ITEM_ELEVATION = 4

export const reorder = (
  list: ComponentModel[],
  startIndex: number,
  endIndex: number,
): ComponentModel[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getItemStyle =
  (theme: Theme) =>
  (isDragging: boolean, draggableStyle: CSSProperties = {}): CSSProperties => ({
    ...draggableStyle,
    ...(isDragging && {
      background: `${theme.palette.grey[100]}`,
      border: `1px solid ${theme.palette.primary.main}`,
    }),
  })

const List: React.FC<StdComponentArgs<ListModel>> = props => {
  const theme = useTheme()
  const [triggerAction, setTriggerAction] = useState(false)
  const { comp, onComponentChange, onCommand } = props
  const children: ComponentModel[] = comp.tchildren || []
  const recommend: ContentHashedComponent[] = comp.trecommend || []

  // handling action when addElem is triggered
  useEffect(() => {
    if (triggerAction && comp.actions && comp.actions.length > 0) {
      comp.actions.map(action => {
        const cCmd = inputActionCmd(action, comp)
        return onCommand(cCmd)
      })
      setTriggerAction(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerAction])

  //TODO move this logic to store/Component
  const addElem = () => {
    const newChildren = [...children, { ...comp.emptycomponent }]
    onComponentChange(
      addIdsToCompIfMissing({
        ...comp,
        tchildren: newChildren,
        numchildren: newChildren.length,
      }),
    )
    setTriggerAction(true)
  }

  // TODO: can we use ID instead?
  const removeElem = (index: number) => {
    const newChildren = children.filter((_, ix) => ix !== index)
    onComponentChange({
      ...comp,
      tchildren: newChildren,
      numchildren: newChildren.length,
    })
  }

  const acceptRecommendation = (hash: ContentHash) => {
    onComponentChange(userAcceptRecommended(hash)(comp))
  }

  const rejectRecommendation = (hash: ContentHash) => {
    onComponentChange(userDeleteRecommended(hash)(comp))
  }

  const onDragEnd = (result: DropResult): void => {
    // dropped outside the list
    if (!result.destination) {
      return
    } else {
      const items = reorder(
        children,
        result.source.index,
        result.destination.index,
      )

      onComponentChange({
        ...comp,
        tchildren: items,
      })
    }
  }

  return (
    <Box className="list" {...getJuvoInfo('ListCont', comp)}>
      <Box
        className="list-header"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '& > :not(:last-child)': {
            marginRight: '2rem',
          },
        }}
      >
        <InputLabel className="juvo-input-label">{comp.label ?? ''}</InputLabel>
        {children.length === 0 && (
          <MuiButton
            variant="text"
            startIcon={<Icon className="fa fa-plus" />}
            juvo-action="list-add"
            onClick={addElem}
            data-testid={`${comp.data_testid}-addListItem-${createSlug(comp.addlabel)}`}
          >
            {comp.addlabel}
          </MuiButton>
        )}
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="childrenComponents">
          {provided => (
            <MuiList
              ref={provided.innerRef}
              sx={{
                paddingTop: 0,
              }}
            >
              {children.map((cp, idx) => {
                const childProps = adjustStdComponentArgs(props, cp, nc =>
                  onComponentChange(containerSetChild(nc, idx, comp)),
                )

                return (
                  <Draggable key={idx} draggableId={`${idx}`} index={idx}>
                    {(provided, snapshot) => (
                      <ListItem
                        className="list-item"
                        component={Paper}
                        elevation={LIST_ITEM_ELEVATION}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        data-testid={`${comp.data_testid}-listItem-${idx}`}
                        sx={{
                          ...getItemStyle(theme)(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          ),
                          display: 'flex',
                          alignItems: 'stretch',
                          position: 'relative',
                          marginTop: '1rem',
                          borderRadius: '0',
                          padding: 0,
                          border: theme =>
                            `1px solid ${theme.palette.secondary.dark}`,
                          boxShadow: 'none',
                          '&:hover': {
                            filter:
                              'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                          },
                        }}
                      >
                        <IconButton
                          className="fa fa-trash"
                          juvo-action="list-delete"
                          color="inherit"
                          size="small"
                          data-testid={`${comp.data_testid}-deleteListItem-${idx}`}
                          sx={{
                            position: 'absolute',
                            top: '0.5rem',
                            right: '0.5rem',
                            fontSize: '1rem',
                            color: style => style.palette.primary.main,
                            '&:hover': {
                              color: style => style.palette.error.main,
                            },
                          }}
                          onClick={() => removeElem(idx)}
                        />
                        <Box
                          className="list-item-drag-handle"
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            background: style => style.palette.secondary.main,
                            alignItems: 'center',
                            width: '24px',
                            '.MuiIcon-root': {
                              fontSize: '1rem',
                            },
                            borderRight: theme =>
                              `1px solid ${theme.palette.grey[300]}`,
                          }}
                          {...provided.dragHandleProps}
                        >
                          <Icon
                            className="fas fa-grip-dots-vertical"
                            fontSize="small"
                            sx={{
                              color: snapshot.isDragging
                                ? theme.palette.primary.dark
                                : 'inherit',
                            }}
                          />
                        </Box>
                        <Box
                          className="list-item-content"
                          sx={{
                            width: '100%',
                            padding: '32px 8px 16px 8px',
                          }}
                        >
                          <SwitchYard {...childProps} />
                        </Box>
                      </ListItem>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </MuiList>
          )}
        </Droppable>
      </DragDropContext>
      {children.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            borderBottom: '1px solid #dddcdc',
          }}
        >
          <MuiButton
            variant="text"
            startIcon={<Icon className="fa fa-plus" />}
            juvo-action="list-add"
            data-testid={`${comp.data_testid}-addListItem-${createSlug(comp.addlabel)}`}
            onClick={addElem}
          >
            {comp.addlabel}
          </MuiButton>
        </Box>
      )}
      {recommend.map((cp, idx) => {
        const childProps = adjustStdComponentArgs(
          props,
          cp.component,
          /* eslint-disable @typescript-eslint/no-empty-function */
          () => {}, //ignore all changes component is not accepted
        )

        return (
          <Paper
            variant="outlined"
            key={idx}
            className="list-item-recommend"
            elevation={LIST_ITEM_ELEVATION}
            sx={{
              border: style => `4px solid ${style.palette.info.main}`,
            }}
          >
            <MuiButton
              juvo-action="list-accept"
              onClick={() => acceptRecommendation(cp.content_hash)}
            >
              Accept
            </MuiButton>
            <MuiButton
              juvo-action="list-reject"
              onClick={() => rejectRecommendation(cp.content_hash)}
            >
              Reject
            </MuiButton>
            <SwitchYard {...childProps} />
          </Paper>
        )
      })}
    </Box>
  )
}

export default List
