import React from 'react'
import MuiButton from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import { Icon } from '@mui/material'
import { Link } from 'react-router-dom'

const HeaderButton: React.FC<{
  icon: string
  title: string
  action: (() => void) | string
  extraProps?: any
}> = ({ icon, title, action, extraProps }) => {
  const isLink = typeof action === 'string'
  const buttonProps = !isLink
    ? {
        onClick: action,
      }
    : {
        to: action,
        component: Link,
      }
  return (
    <Tooltip arrow title={title}>
      <MuiButton
        variant="text"
        aria-label={title}
        size="small"
        sx={{
          color: style => style.palette.grey[300],
          padding: 0,
          minWidth: 'unset',
          flexDirection: 'column',
        }}
        {...buttonProps}
        {...extraProps}
      >
        <Icon
          className={`fas fa-sharp ${icon}`}
          sx={{
            fontSize: '1.5em',
            overflow: 'unset',
          }}
        />
      </MuiButton>
    </Tooltip>
  )
}

export default HeaderButton
