import { isUndefined } from 'lodash'
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { ResultSubType, SnackbarModel } from '../types'
import { isDefined } from '../utils'
import { JuvoSnackbar } from '../components/juvo-component/JuvoSnackbar/JuvoSnackbar'
import { SNACKBAR_DURATION, SNACKBAR_TRANSITION } from '../constants/Constants'

export type QueueMessageProps = {
  title: string
  subtitle: string
  subType: ResultSubType
  duration?: number
}

export const SnackbarContext = createContext<{
  queueMessage: (props: QueueMessageProps) => void
  queueComp: (comp: SnackbarModel) => void
}>({ queueMessage: () => undefined, queueComp: () => undefined })

export const useSnackbar = () => useContext(SnackbarContext)

const SnackbarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const messages = useRef<SnackbarModel[]>([])
  const [currentMessage, setCurrentMessage] = useState<SnackbarModel>()
  const [popTimeout, setPopTimeout] = useState<NodeJS.Timeout>()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (isOpen) close()
    transitionToNewMsg(currentMessage, SNACKBAR_TRANSITION)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMessage])

  const transitionToNewMsg = (
    message: SnackbarModel | undefined,
    delay: number,
  ) => {
    // If there is no current message, grab the next one
    if (isUndefined(message)) {
      popMessage()
      return
    }
    // Otherwise show our message
    setTimeout(() => {
      showSnackbar(message)
    }, delay)
  }

  const showSnackbar = (message: SnackbarModel) => {
    setIsOpen(true)
    // Store the timeout so we can cancel it if the user closes the snackbar early
    setPopTimeout(
      // Timeout to remove the snackbar after its duration
      setTimeout(() => {
        setCurrentMessage(undefined)
      }, message.duration ?? SNACKBAR_DURATION),
    )
  }

  const queueMessage = (msg: QueueMessageProps) => {
    const comp: SnackbarModel = {
      title: msg.title,
      subtitle: msg.subtitle,
      type: 'snackbar',
      sub_type: msg.subType,
      duration: msg.duration ?? SNACKBAR_DURATION,
    }
    queueComp(comp)
  }

  const queueComp = (comp: SnackbarModel) => {
    messages.current = messages.current.concat([comp])
    if (isUndefined(currentMessage)) {
      popMessage()
    }
  }

  const popMessage = () => {
    if (messages.current.length) {
      setCurrentMessage(messages.current[0])
      messages.current = messages.current.splice(1)
    }
  }

  const close = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return
    setIsOpen(false)
    if (reason !== 'buttonclose') return
    if (isDefined(popTimeout)) {
      clearTimeout(popTimeout)
    }
    setCurrentMessage(undefined)
  }

  return (
    <SnackbarContext.Provider value={{ queueMessage, queueComp }}>
      {children}
      <JuvoSnackbar comp={currentMessage} isOpen={isOpen} handleClose={close} />
    </SnackbarContext.Provider>
  )
}

export default SnackbarProvider
