import {
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
  alpha,
} from '@mui/material'
import React from 'react'
import MuiAccordion from '@mui/material/Accordion'

import CopyBox from '../../CopyBox/CopyBox'
import FormattedText from '../FormattedText/FormattedText'

export const AppNotificationSnippet: React.FC<{
  messageRaw: string
  collapse: boolean
  icon?: JSX.Element
}> = ({ messageRaw, collapse, icon }) => {
  if (messageRaw.trim() == '') {
    return <></>
  }
  return (
    <MuiAccordion
      elevation={0}
      disableGutters
      square
      sx={{
        background: alpha('#fff', 0.55),
        border: `1px dashed var(--color-border)`,
        marginTop: '12px',
        width: '100%',
        '&:before': {
          display: 'none',
        },
      }}
    >
      {collapse && (
        <AccordionSummary
          expandIcon={
            <IconButton
              className="fa fa-angle-up"
              size="small"
              aria-label="open accordion"
            />
          }
          sx={{
            flexDirection: 'row-reverse',
            minHeight: 'unset',
            padding: '8px 16px',
            '.MuiAccordionSummary-content': {
              margin: '0px',
              fontSize: '0.9em',
              fontWeight: 500,
            },
            '.MuiAccordionSummary-expandIconWrapper': {
              marginRight: '8px',
              width: '24px',
              height: '24px',
            },
          }}
        >
          Click to expand
        </AccordionSummary>
      )}
      <AccordionDetails
        sx={{
          padding: '8px 16px',
          '.copy-button': {
            display: 'block',
            top: '4px',
            right: '8px',
          },
        }}
      >
        <Typography
          variant="body2"
          data-testid="notificationSecondaryMessage"
          sx={{
            lineHeight: '1.6',
            display: 'flex',
            fontSize: '0.95rem',
          }}
        >
          {icon}
          <FormattedText
            sx={{
              fontFamily: '"Ubuntu Mono", monospace !important',
            }}
          >
            {messageRaw}
          </FormattedText>
        </Typography>
        <CopyBox
          copyContent={messageRaw}
          background="transparent"
          hoverBackground={alpha('#000', 0.1)}
        />
      </AccordionDetails>
    </MuiAccordion>
  )
}
