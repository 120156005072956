import React from 'react'
import { Icon, Typography } from '@mui/material'

import { FileContainer } from './FileContainer'

export const FileError: React.FC<{
  e: string
}> = ({ e }) => {
  return (
    <FileContainer>
      <>
        <Icon
          className={`fa-regular fa-file-exclamation`}
          fontSize="medium"
          sx={{
            color: theme => theme.palette.error.dark,
            fontSize: '1.2rem',
          }}
        />
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '0.9rem',
            color: theme => theme.palette.error.main,
          }}
        >
          {e}
        </Typography>
      </>
    </FileContainer>
  )
}
