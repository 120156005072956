import React from 'react'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'

import { Command, OptionsModel } from '../../../types'
import { setComponentValue } from '../../../store'
import {
  getJuvoProps,
  sanitizeInputName,
  ValidationProps,
} from '../../../utils'
import { InfoTooltip } from '../InfoTooltip/InfoTooltip'

const OptionsField: React.FC<{
  comp: OptionsModel
  onCommand: (cmd: Command) => void
  onChange: (_: OptionsModel) => void
  validationProps: ValidationProps
}> = ({ comp, onCommand, onChange, validationProps }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(setComponentValue(comp)(e.target.value))
  }
  const { eventHandlers, className, value, juvoInfo } = getJuvoProps(
    'Options',
    comp,
    onCommand,
    handleChange,
  )
  const { onClick: onClickFromServer, ...handlersFromServer } = eventHandlers
  const label = comp.text || ''
  const { handleVisited, hasErrors, errorMessage } = validationProps

  return (
    <FormControl
      {...juvoInfo}
      component="fieldset"
      error={hasErrors}
      sx={{ display: 'block', marginBottom: theme => theme.spacing(2) }}
    >
      <FormLabel component="legend" className="juvo-input-label">
        {label}
        {comp.tooltip && <InfoTooltip tooltip={comp.tooltip} />}
      </FormLabel>
      <RadioGroup
        row
        aria-label={label}
        name={sanitizeInputName(label)}
        {...handlersFromServer}
        onClick={() => {
          handleVisited()
          onClickFromServer && onClickFromServer()
        }}
        value={value}
        className={className}
      >
        {comp.options.map(option => (
          <FormControlLabel
            data-testid={`${comp.data_testid}-${option}`}
            key={option}
            label={option}
            value={option}
            control={
              <Radio
                className="radio-button"
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              />
            }
            sx={{
              '&:hover': {
                // radio-button class was added to the Radio component to propagate the hover of the label to the radio button
                '.radio-button': {
                  backgroundImage:
                    'radial-gradient(white, white 30%, #D7D0F5 35%, #D7D0F5 40%, transparent 50%, transparent)',
                  color: theme => theme.palette.primary.main,
                },
              },
            }}
          />
        ))}
      </RadioGroup>
      {hasErrors && <FormHelperText error>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}

export default OptionsField
