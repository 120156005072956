import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Popper,
  PopperProps,
} from '@mui/material'
import React, { MutableRefObject } from 'react'

import { isDefined } from '../../../utils'

const AutocompleteTextField: React.FC<{
  inputRef: MutableRefObject<undefined>
  inputName: string
  suggestions: string[]
  setValue: (v: string) => void
  inputValue: string
  getBaseTextInput: (params: AutocompleteRenderInputParams) => React.ReactNode
}> = ({
  inputRef,
  inputName,
  suggestions,
  setValue,
  inputValue,
  getBaseTextInput,
}) => {
  const getInputWidth = (myProps: PopperProps): number | undefined => {
    const anchor = myProps.anchorEl
    if (isDefined(anchor) && anchor instanceof HTMLElement) {
      const child = anchor.querySelector('input')
      if (isDefined(child)) {
        return child.clientWidth
      } else {
        return anchor.clientWidth
      }
    }
  }

  const getPopper = (props: PopperProps) => {
    const width = getInputWidth(props)
    return (
      <Popper
        {...props}
        placement="bottom-start"
        sx={{
          border: '1px solid var(--color-border)',
          ...(isDefined(width) && { width: `${width}px!important` }),
        }}
        anchorEl={inputRef.current}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 2],
            },
          },
        ]}
      />
    )
  }

  return (
    <Autocomplete
      freeSolo
      id={`${inputName}-autocomplete`}
      options={suggestions}
      onInputChange={(_event, value) => setValue(value)}
      inputValue={inputValue}
      PopperComponent={props => getPopper(props)}
      renderInput={(params: AutocompleteRenderInputParams) =>
        getBaseTextInput(params)
      }
    />
  )
}

export default AutocompleteTextField
