import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const RouteTracker: React.FC<{
  children: React.ReactElement
  updateLocation: () => void
}> = ({ children, updateLocation }) => {
  const location = useLocation()

  useEffect(() => {
    updateLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return children
}
