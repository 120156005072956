import { useState } from 'react'

import { AppConcurrency, ComponentModel } from '../types'
import { isDefined } from '../utils'

export type MessageLogEntry = {
  reqId: string
  triggeringComponent: ComponentModel[]
  currentState: ComponentModel[]
  prevState: ComponentModel[]
  protocol: AppConcurrency
}

export type MessageLog = Map<string, MessageLogEntry>

const emptyMsgLog: MessageLog = new Map<string, MessageLogEntry>()

export const useMessageHistory = () => {
  const [messageLog, setMessageLog] = useState<MessageLog>(emptyMsgLog)

  const addMessage = (
    reqId: string,
    trigger: ComponentModel,
    currentComps: ComponentModel[],
    prevComps: ComponentModel[],
    protocol: AppConcurrency,
  ) => {
    const newMsg = {
      reqId: reqId,
      triggeringComponent: trigger,
      currentState: currentComps,
      prevState: prevComps,
      protocol: protocol,
    }
    setMessageLog(messageLog.set(reqId, newMsg))
  }

  const popMessage = (reqId: string): MessageLogEntry | null => {
    const msg = messageLog.get(reqId)
    if (isDefined(msg)) {
      messageLog.delete(reqId)
      return msg
    }
    return null
  }

  return { addMessage, popMessage }
}
