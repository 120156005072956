import React, { createContext, useContext, useState } from 'react'

import { logInfo } from '../utils'

export const GuidContext = createContext<{
  guid: string
  updateGuid: (newGuid: string) => void
}>({ guid: '', updateGuid: () => undefined })

export const useGuid = () => useContext(GuidContext)

const GuidProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [guid, setGuid] = useState('')

  const updateGuid = (guid: string) => {
    logInfo('Updating guid: ' + guid)
    setGuid(guid)
  }

  return (
    <GuidContext.Provider value={{ guid, updateGuid }}>
      {children}
    </GuidContext.Provider>
  )
}

export default GuidProvider
