import React, { createContext, useContext, useState } from 'react'

export interface UploadingContextProps {
  isUploading: boolean
  setIsUploading: (value: boolean) => void
}

export const UploadingContext = createContext<UploadingContextProps>({
  isUploading: false,
  setIsUploading: () => undefined,
})

export const useUploading = () => useContext(UploadingContext)

const UploadingProvider: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const [isUploading, setIsUploading] = useState(false)

  return (
    <UploadingContext.Provider value={{ isUploading, setIsUploading }}>
      {children}
    </UploadingContext.Provider>
  )
}

export default UploadingProvider
