import React from 'react'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'

import {
  alertOn,
  AppId,
  AppRegistration,
  displayDismissed,
  displayReplyState,
  FileDownloadsModel,
  LinkDownload,
  ReplyState,
  WarningIssue,
} from '../../../types'
import { genGuid, Nullable } from '../../../utils'
import {
  CUSTOM_XML_APP_ID,
  NOTIFICATION_MESSAGE_CUTOFF,
} from '../../../constants/Constants'
import {
  getAlertSeverity,
  getBatchResultSeverity,
  getNotificationIconFromSeverity,
} from '../../../utils/Notifications'
import { AppNotificationSnippet } from '../AppNotificationSnippet/AppNotificationSnippet'
import { JuvoAlertContainer } from '../../../containers/JuvoAlert/JuvoAlertContainer'
import { FileDownload } from '../FileDownload/FileDownload'

const AppNotification: React.FC<{
  appId: string
  appReg: AppRegistration
  handleDismissCommandAlert: (appId: AppId, appReg: AppRegistration) => void
  globalWarn: Nullable<WarningIssue>
  dismissGlobalWarn: () => void
}> = ({
  appId,
  appReg,
  handleDismissCommandAlert,
  globalWarn,
  dismissGlobalWarn,
}) => {
  const command = appReg.app_skeleton.command
  const cmdMessage =
    command?.display_state?.message || 'Developer Error (missing msg)'
  const dispState = command?.display_state?.state
  const cmdSubMessage = command?.display_state?.sub_message || ''
  const bigSubMsg =
    cmdSubMessage.length > NOTIFICATION_MESSAGE_CUTOFF ||
    cmdSubMessage.indexOf('\n') !== -1
  const replyState = displayReplyState(command)
  const showCommandAlert = !displayDismissed(command) && alertOn(command)
  const collapseDetails =
    dispState !== 'Success' && dispState !== 'SuccessFinal' && bigSubMsg

  // HACK: avoid double Loading component and AppNotification when the command is 'Loading' for custom apps
  if (
    command?.display_state?.state === 'Loading' &&
    appId === CUSTOM_XML_APP_ID
  ) {
    return null
  }

  if (showCommandAlert) {
    const links = command?.display_state?.download_links || []
    // Gross hack to make backward compatible with new link type
    const downloadLinks: Nullable<LinkDownload[]> = links.map(link => {
      return {
        '@': 'FileDownloadLink',
        name: link.name,
        url: link.link,
        preview_url: null,
      }
    })
    const batchResults = command?.display_state?.batch_results || []
    const severity = getAlertSeverity(replyState)
    const downloadsComponent: FileDownloadsModel = {
      id: genGuid(),
      links: downloadLinks,
      type: 'file-downloads',
    }

    return (
      <JuvoAlertContainer
        severity={severity}
        onClose={() => handleDismissCommandAlert(appId, appReg)}
        title={cmdMessage}
      >
        <>
          <AppNotificationSnippet
            collapse={collapseDetails}
            messageRaw={cmdSubMessage}
          />
          {links.length > 0 && <FileDownload component={downloadsComponent} />}
          {batchResults.length > 0 && (
            <Box>
              {batchResults.map((batchResult, index) => (
                <AppNotificationSnippet
                  key={index}
                  collapse={false}
                  messageRaw={batchResult.message ?? ''}
                  icon={getNotificationIconFromSeverity(
                    getBatchResultSeverity(batchResult),
                    { marginRight: '8px', fontSize: '16px' },
                  )}
                />
              ))}
            </Box>
          )}
        </>
      </JuvoAlertContainer>
    )
  } else if (globalWarn) {
    return (
      <JuvoAlertContainer
        severity={getAlertSeverity(ReplyState.Failure)}
        onClose={dismissGlobalWarn}
        title="App Warning"
      >
        <>
          <Typography
            variant="body1"
            data-testid="notificationMessage"
            sx={{
              fontWeight: 500,
              marginBottom: '8px',
            }}
          >
            {globalWarn.warn}
          </Typography>
          <Typography variant="body2">Technical Details:</Typography>
          <Typography
            variant="body2"
            data-testid="notificationSecondaryMessage"
          >
            {JSON.stringify(globalWarn.dta)}
          </Typography>
        </>
      </JuvoAlertContainer>
    )
  } else {
    return null
  }
}

export default AppNotification
