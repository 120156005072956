import React, { CSSProperties, useRef } from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import {
  AutocompleteRenderInputParams,
  Box,
  InputBase,
  Theme,
  Tooltip,
  useTheme,
} from '@mui/material'
import FormHelperText from '@mui/material/FormHelperText'
import clsx from 'clsx'

import { getJuvoInfo } from '../../../store'
import {
  InputStateProps,
  arrDefv,
  isDefined,
  isUndefined,
  sanitizeInputName,
} from '../../../utils'
import { TextInputModel } from '../../../types'

import AutocompleteTextField from './AutocompleteTextField'

/**
 * Currently serves both generic type="input", no sub-type and "textarea"
 */
const InputTextField: React.FC<{
  comp: TextInputModel
  multiline: boolean
  inputStateProps: InputStateProps
}> = ({ comp, multiline, inputStateProps }) => {
  const juvoInfo = getJuvoInfo('TextInput', comp)
  const label = comp.text || ''
  const recommendation = comp.recommendation
  const inputRef = useRef()
  const {
    value,
    setValue,
    handleBlur,
    handlersFromServer,
    hasErrors,
    errorMessage,
  } = inputStateProps
  const theme = useTheme()
  const inputName = sanitizeInputName(label)
  const inputValue = isDefined(value)
    ? value
    : isDefined(recommendation)
      ? recommendation
      : ''
  const isReadOnly = comp.input_options
    ? comp.input_options['read-only']
    : false
  // Display-only is an extra display option on top of read-only. If the input is not already read-only, then it cannot be display-only
  const isDisplayOnly = comp.input_options
    ? isReadOnly && comp.input_options['display-only']
    : false
  const isHidden = comp.input_options ? comp.input_options['hidden'] : false
  const isPassword = comp.input_options ? comp.input_options['password'] : false
  const suggestions = arrDefv(comp.suggestions)(
    arrDefv(comp.bound?.suggestions?.value)(
      arrDefv(comp.bound?.suggestions?.recommendation)([]),
    ),
  )

  const getDisplayOnlyStyle = (theme: Theme): CSSProperties => ({
    ...{
      padding: '12px 16px',
      background: theme.palette.secondary.main,
      alignItems: 'center',
      display: 'inline-flex',
      gap: '12px',
      flexDirection: 'row',
      label: {
        transform: 'none',
        fontSize: '0.8rem',
        color: theme.palette.grey[700],
        textWrap: 'nowrap !important',
        textOverflow: 'unset !important',
        overflow: 'unset !important',
      },
      'input, textarea': {
        background: 'transparent !important',
        border: 'none !important',
        outline: 'none !important',
        color: theme.palette.text.primary,
        padding: '0 !important',
        WebkitTextFillColor: 'unset !important',
        textOverflow: 'ellipsis',
      },
      '.juvo-input-base:after': {
        display: 'none',
      },
      '.juvo-compare-input-base': {
        outline: 'none !important',
        padding: '0 !important',
        overflow: 'hidden',
      },
    },
  })

  const getBaseTextInput = (params?: AutocompleteRenderInputParams) => (
    <FormControl
      error={hasErrors}
      variant="standard"
      fullWidth
      className="juvo-input-control"
      sx={{
        display: isHidden ? 'none' : 'flex',
        ...(isDisplayOnly && getDisplayOnlyStyle(theme)),
      }}
      ref={params?.InputProps.ref ?? undefined}
      {...juvoInfo}
    >
      <InputLabel shrink className="juvo-input-label">
        {label}
      </InputLabel>
      <Tooltip
        arrow
        title={
          isUndefined(comp.tooltip_icon) && comp.tooltip ? comp.tooltip : ''
        }
      >
        <InputBase
          {...handlersFromServer}
          data-testid={comp.data_testid}
          type={isPassword ? 'password' : 'text'}
          id={inputName}
          name={inputName}
          placeholder={comp.placeholder}
          value={inputValue}
          disabled={isReadOnly}
          startAdornment={
            <>
              {isDefined(comp.tooltip_icon) && (
                <Tooltip title={comp.tooltip ?? ''} arrow>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      left: '0',
                      color: style => style.palette.primary.main,
                      cursor: 'default',
                      zIndex: 1,
                      padding: '8px 16px',
                    }}
                  >
                    <i className={`fa-solid ${comp.tooltip_icon}`}></i>
                  </Box>
                </Tooltip>
              )}
            </>
          }
          onChange={
            isDefined(params)
              ? undefined
              : event => setValue(event.target.value)
          }
          onBlur={handleBlur}
          ref={inputRef}
          sx={{
            input: {
              paddingLeft: isDefined(comp.tooltip_icon)
                ? '40px !important'
                : 'inherit',
            },
            width: '100%',
          }}
          multiline={multiline}
          inputProps={{
            ...params?.inputProps,
          }}
          className={clsx(
            'juvo-input-base',
            isDefined(recommendation) && isUndefined(value) && 'recommendation',
          )}
        />
      </Tooltip>
      {!isDisplayOnly && hasErrors && (
        <FormHelperText sx={{ visibility: hasErrors ? 'visible' : 'hidden' }}>
          {errorMessage || ' '}
        </FormHelperText>
      )}
    </FormControl>
  )

  return suggestions.length > 0 ? (
    <AutocompleteTextField
      inputRef={inputRef}
      inputName={inputName}
      suggestions={suggestions}
      setValue={setValue}
      inputValue={inputValue}
      getBaseTextInput={getBaseTextInput}
    />
  ) : (
    getBaseTextInput()
  )
}

export default InputTextField
