import React from 'react'
import { Typography, TypographyProps } from '@mui/material'

interface FormattedTextProps extends TypographyProps {
  children: string
}

export const cleanMessage = (msg: string) => {
  return msg.replace(/\\n/g, '\n').replace(/<\/?[^>]+(>|$)/g, '')
}

const FormattedText: React.FC<FormattedTextProps> = ({
  children,
  ...props
}) => {
  const { sx, ...otherProps } = props

  return (
    <Typography
      variant="body2"
      sx={{
        lineHeight: '1.6',
        whiteSpace: 'pre-wrap',
        display: 'flex',
        ...sx,
      }}
      {...otherProps}
    >
      {cleanMessage(children)}
    </Typography>
  )
}

export default FormattedText
