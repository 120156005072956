import React, { DragEventHandler, useEffect, useState } from 'react'
import { Box, Icon, Typography } from '@mui/material'
import { alpha } from '@mui/material'

import { Command, FileDropModel, FileModel, UploadModel } from '../../../types'
import { mergedComponentEvents } from '../../../store'
import { Nullable, isDefined } from '../../../utils/Undefined'
import { logWarn } from '../../../utils/Logger'
import { useSnackbar } from '../../../providers/SnackbarProvider'
import {
  setFileDropValue,
  getFileContent,
  concatFiles,
  isFileAccepted,
} from '../../../store/File'
import { getDownloadIconFromFileName } from '../../../utils/Common'
import { useUploading } from '../../../providers/UploadingProvider'

import { FileContainer } from './FileContainer'
import { UploadLoadingIndicator } from './UploadLoadingIndicator'

export const UploadInput: React.FC<{
  compData: UploadModel | FileDropModel
  onCommand: (cmd: Command) => void
  onChange: (_: any) => void
  accept: Nullable<string>
  text: Nullable<string>
  multiple?: boolean
  exteriorLoading?: boolean
}> = ({
  compData,
  onChange,
  onCommand,
  accept,
  text,
  multiple = false,
  exteriorLoading = false,
}) => {
  const { setIsUploading } = useUploading()
  const [isLoading, setIsLoading] = useState(false)
  const { queueMessage } = useSnackbar()

  const files = compData.files_raw ?? []
  const isNonMultipleFilled = !multiple && files.length > 0

  useEffect(() => {
    setIsUploading(exteriorLoading || isLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, exteriorLoading])

  files.forEach(value => {
    if (!isDefined(value.success) && value.contents) {
      value.success = true
    }
  })

  const clearField = () => {
    onChange(setFileDropValue(compData, []))
  }

  const checkFilesPresent = (
    newFiles: File[],
    oldFiles: FileModel[],
  ): File[] => {
    return newFiles.reduce((accumulator: File[], currentValue: File) => {
      if (!oldFiles.some(obj => obj.name === currentValue.name)) {
        accumulator.push(currentValue)
      }
      return accumulator
    }, [])
  }

  const processFiles = async (files: FileList | null): Promise<void> => {
    if (isNonMultipleFilled) return
    if (isDefined(files) && files.length > 0) {
      setIsLoading(true)
      const trimmedFiles = checkFilesPresent(
        Array.from(files),
        compData.files_raw ?? [],
      )
      const getAllContents = trimmedFiles.map(async file => {
        if (!isFileAccepted(accept, file)) {
          queueMessage({
            title: 'File upload error',
            subtitle: 'That file type is not accepted.',
            subType: 'failure',
          })
          return {
            name: file.name,
            contents: '',
            success: false,
          }
        }
        const fileData = await getFileContent(file).catch(reason => {
          logWarn(`File failed to upload: ${reason}`)
          queueMessage({
            title: 'File upload error',
            subtitle: 'File failed to upload. Please try again.',
            subType: 'failure',
          })
          return ''
        })
        return {
          name: file.name,
          contents: fileData,
          success: isDefined(fileData) && fileData !== '',
        }
      })
      const allFiles = await Promise.all(getAllContents).finally(() => {
        setIsLoading(false)
      })
      const concFiles = concatFiles(compData, allFiles) ?? []
      onChange(setFileDropValue(compData, concFiles))
    } else {
      clearField()
    }
  }

  const onChangeHandler = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.currentTarget.files
    if (isDefined(files)) {
      await processFiles(files)
    }
  }

  const evAttr: any = mergedComponentEvents(
    () => compData,
    { type: 'from-stablestate', fn: onCommand },
    onChangeHandler,
  )

  const removeFile = (filename: string): void => {
    const filtered = compData.files_raw?.filter(f => f.name !== filename)
    onChange(setFileDropValue(compData, filtered ?? []))
  }

  const handleDrop: DragEventHandler<HTMLDivElement> = async event => {
    event.preventDefault()
    event.stopPropagation()
    // Prevent multiple drops if multiple=false
    if (isNonMultipleFilled) return
    const files = event.dataTransfer?.files
    await processFiles(files)
  }

  return (
    <>
      <Box
        sx={{
          border: theme => `1px dashed ${theme.palette.primary.main}`,
          padding: '24px',
          textAlign: 'center',
          marginBottom: '8px',
          position: 'relative',
          transition: 'all 0.14s ease',
          '&:hover': {
            background: theme => alpha(theme.palette.primary.light, 0.2),
            '.fa-file-arrow-up': {
              color: theme => theme.palette.primary.main,
            },
          },
          '& input[type="file"]': {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0,
            cursor: 'pointer',
          },
        }}
        onDrop={handleDrop}
        onDragOver={e => e.preventDefault()}
      >
        {exteriorLoading || isLoading ? (
          <UploadLoadingIndicator
            exteriorLoading={exteriorLoading}
            interiorLoading={isLoading}
          />
        ) : (
          <>
            <Icon
              className="fa-solid fa-file-arrow-up"
              fontSize="large"
              sx={{
                color: theme => theme.palette.text.secondary,
                transition: 'all 0.14s ease',
                fontSize: '4rem',
              }}
            />
            <input
              type="file"
              {...evAttr}
              disabled={exteriorLoading || isNonMultipleFilled}
              accept={accept}
              aria-labelledby={`${compData.id}-label`}
              id={compData.id}
              data-testid={compData.data_testid}
            />
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '1.2rem',
              }}
              id={`${compData.id}-label`}
            >
              {text}
            </Typography>
            <Typography
              sx={{
                fontSize: '0.8rem',
              }}
            >
              Drag and drop, or click to browse
            </Typography>
          </>
        )}
      </Box>

      {files.map((file, idx) => (
        <FileContainer
          key={idx}
          success={file.success}
          removeAction={() => removeFile(file.name)}
        >
          <>
            <Icon
              className={`fa-regular ${getDownloadIconFromFileName(file.name)}`}
              sx={{
                color: theme => theme.palette.text.secondary,
                fontSize: '1.2rem',
              }}
            />
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '0.9rem',
                color: theme => theme.palette.text.secondary,
              }}
            >
              {file.name}
            </Typography>
          </>
        </FileContainer>
      ))}
    </>
  )
}
