import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'

import { ChatDisplayModel, ChatRole, User } from '../../../types'
import { getTwoLetters } from '../../../utils'
import CopyBox from '../../CopyBox/CopyBox'

import logo from './robot-head.svg'

const scrollChatDisplay = (): void => {
  const appContent = document.querySelector('#chat-display')
  if (appContent) {
    appContent.scrollTop = appContent.scrollHeight
  }
}

const ChatDisplay: React.FC<{ comp: ChatDisplayModel; user: User }> = ({
  comp,
  user,
}) => {
  const theme = useTheme()

  useEffect(() => {
    scrollChatDisplay()
  }, [comp.value])

  const getBackgroundColor = (type: ChatRole) => {
    return type === 'assistant'
      ? theme.palette.secondary.main
      : theme.palette.primary.main
  }

  const getAnimation = (type: ChatRole) => {
    return {
      animation: `0.5s cubic-bezier(.19,.27,.05,1.03) ${type === 'assistant' ? 'chatInFromLeft 0.7s' : 'chatInFromRight'}`,
      animationFillMode: 'forwards',
    }
  }

  const getInitialStyle = (type: ChatRole) => {
    return {
      opacity: 0,
      transform: `translateX(${type === 'assistant' ? '-25%' : '25%'})`,
    }
  }

  const setBeforeStyles = (type: ChatRole) => {
    return type === 'assistant'
      ? {
          borderRight: `20px solid ${theme.palette.secondary.main}`,
          left: '-15px',
          borderRadius: '20% 0% 0% 0%',
        }
      : {
          borderLeft: `20px solid ${theme.palette.primary.main}`,
          right: '-15px',
          borderRadius: '0% 20% 0% 0%',
        }
  }

  return (
    <Box
      id="chat-display"
      sx={{
        outline: '1px solid var(--color-border)',
        padding: 4,
        overflowX: 'hidden',
        background: styles => styles.palette.common.white,
        overflowY: 'auto',
        maxHeight: '725px',
        p: '16px 32px',
      }}
    >
      <List>
        {comp.value && comp.value.length > 0 ? (
          comp.value.map((message, index) => (
            <ListItem
              key={message.date.toLocaleString()}
              sx={{
                alignItems: 'flex-start',
                background: getBackgroundColor(message.role),
                padding: '10px 20px',
                '&:before': {
                  content: '""',
                  position: 'absolute',
                  width: 0,
                  height: 0,
                  borderTop: '0px solid transparent',
                  borderBottom: '15px solid transparent',
                  top: 0,
                  ...setBeforeStyles(message.role),
                },
                margin: '16px 0px',
                ...getInitialStyle(message.role),
                ...getAnimation(message.role),
              }}
              data-testid={`chat-display-item-${message.role}-${index}`}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {message.role === 'assistant' && (
                  <ListItemAvatar>
                    <Avatar src={logo} alt="JUVO" />
                  </ListItemAvatar>
                )}
                <ListItemText
                  sx={{
                    '.code': {
                      color:
                        message.role === 'assistant'
                          ? theme.palette.text.disabled
                          : theme.palette.grey[400],
                      display: 'flex',
                      margin: '10px 0px 0px 0px',
                    },
                  }}
                  primary={
                    <Box
                      sx={{
                        position: 'relative',
                        '&:hover': {
                          '.copy-button': {
                            display: 'block',
                          },
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          color:
                            message.role === 'assistant'
                              ? theme.palette.text.primary
                              : theme.palette.common.white,
                        }}
                      >
                        {message.content}
                      </Typography>
                      {message.role === 'assistant' && (
                        <CopyBox
                          copyContent={message.content}
                          background={theme.palette.grey[300]}
                          hoverBackground={theme.palette.grey[400]}
                        />
                      )}
                    </Box>
                  }
                  primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                  secondaryTypographyProps={{ className: 'code' }}
                  secondary={new Date(message.date).toLocaleString()}
                />
                {message.role === 'user' && (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: '#F15C5E', fontWeight: '600' }}>
                      {getTwoLetters(user.userName)}
                    </Avatar>
                  </ListItemAvatar>
                )}
              </Box>
            </ListItem>
          ))
        ) : (
          <Typography variant="body1">
            To start using the chatbot, just type a message!
          </Typography>
        )}
      </List>
    </Box>
  )
}

export default ChatDisplay
