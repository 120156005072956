import React from 'react'
import { Box, alpha, Typography, IconButton } from '@mui/material'

export const FileContainer: React.FC<{
  children: React.ReactElement
  success?: boolean
  removeAction?: () => void
}> = ({ children, success = false, removeAction = null }) => {
  return (
    <Box
      sx={{
        padding: '8px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: theme =>
          `1px solid ${
            success ? theme.palette.secondary.dark : theme.palette.error.main
          }`,
        marginTop: '6px',
        background: theme =>
          success
            ? theme.palette.secondary.main
            : alpha(theme.palette.error.light, 0.2),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '6px',
          alignItems: 'center',
        }}
      >
        {children}

        {!success && (
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '0.9rem',
              color: theme => theme.palette.error.main,
            }}
          >
            - File failed to upload
          </Typography>
        )}
      </Box>
      {removeAction && (
        <Box
          sx={{
            alignItems: 'center',
          }}
        >
          <IconButton
            className="fa-regular fa-trash"
            size="small"
            sx={{
              color: theme => theme.palette.error.main,
              width: '22px',
              fontSize: '1rem',
              height: '22px',
            }}
            onClick={() => removeAction()}
          />
        </Box>
      )}
    </Box>
  )
}
