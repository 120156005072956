import {
  FileModel,
  Base64,
  FileDropModel,
  UploadModel,
  LinkUpload,
} from '../types'
import { Nullable, isDefined, isUndefined } from '../utils'

//this for loop scales to bigger files
export const arrayBufferToBase64 = (buffer: Uint8Array) => {
  let binary = ''
  const len = buffer.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(buffer[i])
  }
  return btoa(binary)
}

export const base64ToArrayBuffer = (str: string): Uint8Array => {
  const decoded = atob(str)
  const buf = new ArrayBuffer(decoded.length)
  const bufView = new Uint8Array(buf)
  for (let i = 0, strLen = decoded.length; i < strLen; i++) {
    bufView[i] = decoded.charCodeAt(i)
  }
  return bufView
}

export const setUploadLinksValue = (
  component: UploadModel,
  newLinks: LinkUpload[],
) => ({
  ...component,
  links: newLinks,
})

export const setFileDropValue = <T extends UploadModel | FileDropModel>(
  component: T,
  files: FileModel[],
): T => ({
  ...component,
  files_raw: files.length === 0 ? null : files,
})

export const testMimeType = (accept: string, fileType: string): boolean => {
  return accept.split(',').some(s => {
    const re = new RegExp(s)
    if (re.test(fileType)) {
      return true
    }
  })
}

export const isFileAccepted = (accept: Nullable<string>, f: File) => {
  if (isUndefined(accept)) {
    return true
  }
  const containsMimeType = testMimeType(accept, f.type)
  const containsExtension = accept.split(',').includes(getFileExtension(f))
  return containsExtension || containsMimeType
}

export const concatFiles = <T extends UploadModel | FileDropModel>(
  component: T,
  files: FileModel[],
): FileModel[] | null => {
  if (isDefined(component.files_raw) && component.files_raw.length > 0) {
    return component.files_raw.concat(files)
  }
  return files.length === 0 ? null : files
}

export const getFileExtension = (f: File) => {
  const name = f.name
  const lastDot = name.lastIndexOf('.')
  return name.substring(lastDot)
}

export async function getFileArrayBuffer(file: File): Promise<Uint8Array> {
  const reader = new FileReader()

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      const result = reader.result as ArrayBuffer
      resolve(new Uint8Array(result))
    }
    reader.onerror = () => {
      reject(reader.error)
    }
    reader.readAsArrayBuffer(file)
  })
}

export async function getFileContent(file: File): Promise<Base64> {
  return new Promise((resolve, reject) => {
    getFileArrayBuffer(file)
      .then(buffer => {
        resolve(arrayBufferToBase64(buffer))
      })
      .catch(e => {
        reject(e)
      })
  })
}
