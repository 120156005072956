import React from 'react'

import { ComponentModel, isWhenModel, StdComponentArgs } from '../../../types'
import When from '../../juvo-container/When/When'
import { isUndefined } from '../../../utils'

import SwitchYardNoWhen from './SwitchYardNoWhen'

const SwitchYard: React.FC<StdComponentArgs<ComponentModel>> = props => {
  const comp = props.comp
  if (isUndefined(comp)) {
    return <h2>Developer error, null model in switchyard</h2>
  }
  if (isWhenModel(comp)) {
    return (
      <div className="when-combo">
        {/* The top component defines the when condition in model value */}
        <SwitchYardNoWhen {...props} />
        <When {...props} />
      </div>
    )
  } else {
    return (
        <SwitchYardNoWhen {...props} />
    )
  }
}

export default SwitchYard
