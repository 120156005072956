import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import { Typography } from '@mui/material'

import SwitchYard from '../../components/juvo-component/SwitchYard/SwitchYard'
import {
  appOverSkeleton,
  appRegistrationSetComponent,
  CustomComponentHandler,
  getJuvoInfo,
  SuccessState,
} from '../../store'
import {
  allocateTabs,
  AppId,
  AppRegistration,
  Command,
  commandMessage,
  ComponentModel,
  dismissDisplay,
  Message,
  WarningIssue,
} from '../../types'
import AppNotification from '../../components/juvo-component/AppNotification/AppNotification'
import Loading from '../../components/Loading/Loading'
import Tabs from '../../components/Tabs/Tabs'
import { imGet } from '../../utils/ImmutableMap'
import AppResultNotification from '../../components/juvo-component/AppResultNotification/AppResultNotification'
import { JuvoDialog } from '../JuvoModal/JuvoDialog'
import NotFound from '../../components/ErrorScreens/NotFound/NotFound'
import { isDefined, isUndefined, Nullable } from '../../utils/Undefined'
import { trackDDEvent } from '../../utils/Logger'
import { useGuid } from '../../providers/GuidProvider'
import AppHelp from '../../components/juvo-component/AppHelp/AppHelp'

type AddletEffParams = {
  id: string
}

const Addlet: React.FC<{
  customReactComps: CustomComponentHandler
  state: SuccessState
  onAppChange: (appId: AppId, appReg: AppRegistration) => void
  onComponentChange: (appId: AppId) => (c: ComponentModel) => void
  onOutMsg: (c?: ComponentModel) => (msg: Message) => Promise<void>
  globalWarn: Nullable<WarningIssue>
  dismissGlobalWarn: () => void
}> = ({
  customReactComps,
  state,
  onAppChange,
  onComponentChange,
  onOutMsg,
  globalWarn,
  dismissGlobalWarn,
}) => {
  const { id } = useParams<AddletEffParams>() as AddletEffParams
  const app = imGet(state.apps)(id)
  const handleComponentChange = onComponentChange(id)
  const [open, setOpen] = useState(true)
  const { updateGuid } = useGuid()

  useEffect(() => {
    updateGuid(state.guid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.guid])

  useEffect(() => {
    const [, appReg] = app ?? []
    if (isDefined(appReg)) {
      trackDDEvent('load-app', {
        'app-name': appReg.app_name,
        'app-id': appReg.app_id,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (isUndefined(app)) {
    return <NotFound message="Invalid app ID." homeLink />
  }

  const [, appReg] = app

  const dispState = appReg.app_skeleton.command?.display_state?.state

  const dispat = appReg.app_skeleton.command && appReg.app_skeleton.command['@']

  const sendMsgHandler =
    (oldC: ComponentModel) =>
    async (cmd: Command, c?: ComponentModel): Promise<void> => {
      // this just sets the out command
      onAppChange(id, { ...appReg, out_command: cmd })
      // this assumes that all commands are sent out as a message
      // JUVO-844 make redundant adjustment to appReg that is send out
      // modifying app state happens elsewere, this fixes a race condition
      // caused by MakeInputState optimization
      const modappreg = isDefined(c)
        ? appRegistrationSetComponent(c)(appReg)
        : appReg
      const compM = commandMessage(modappreg)(cmd)

      await onOutMsg(oldC)(compM.msg)
    }
  const tabComps = allocateTabs(appReg.app_skeleton)

  const content = match(tabComps)
    .with({ type: 'notabs' }, ({ data }) => (
      <>
        <AppHelp app={appReg} />
        {data.map((comp, idx) => (
          <SwitchYard
            key={idx}
            customReactComps={customReactComps}
            comp={comp}
            onComponentChange={handleComponentChange}
            onCommand={sendMsgHandler(comp)}
            appInfo={app}
            user={state.juvoUser}
          />
        ))}
      </>
    ))
    .with({ type: 'tabs' }, ({ data }) => (
      <>
        <AppHelp app={appReg} />
        <Tabs
          data={data}
          renderTab={tab => (
            <>
              {tab.components.map((comp, idx) => (
                <SwitchYard
                  key={idx}
                  customReactComps={customReactComps}
                  comp={comp}
                  onComponentChange={handleComponentChange}
                  onCommand={sendMsgHandler(comp)}
                  appInfo={app}
                  user={state.juvoUser}
                />
              ))}
            </>
          )}
        />
      </>
    ))
    .exhaustive()

  const handleDismissCommandAlert = (
    appId: AppId,
    ar: AppRegistration,
  ): void => {
    const command = ar.app_skeleton.command
    if (command) {
      onAppChange(
        appId,
        appOverSkeleton(
          () => ({
            ...ar.app_skeleton,
            command: dismissDisplay(command),
          }),
          ar,
        ),
      )
    }
  }
  return (
    // eslint-disable-next-line react/no-unknown-property
    <div juvo-comp="addlet" juvo-app-id={id} {...getJuvoInfo('addlet', app)}>
      {appReg.app_popup && (
        <JuvoDialog
          title={appReg.app_popup.popup_title}
          buttonText="Accept"
          open={open}
          handleClose={() => setOpen(false)}
          severity="info"
        >
          <Typography
            sx={{
              whiteSpace: 'pre-line',
            }}
          >
            {appReg.app_popup.popup_text}
          </Typography>
        </JuvoDialog>
      )}

      {dispat === 'display-result' ? (
        <AppResultNotification
          appId={id}
          appReg={appReg}
          warning={app[0]}
          user={state.juvoUser}
          customReactComps={customReactComps}
          handleDismissCommandAlert={handleDismissCommandAlert}
        />
      ) : (
        <AppNotification
          appId={id}
          appReg={appReg}
          handleDismissCommandAlert={handleDismissCommandAlert}
          globalWarn={globalWarn}
          dismissGlobalWarn={dismissGlobalWarn}
        />
      )}
      <Loading appReg={appReg} />
      {dispState !== 'SuccessFinal' && content}
    </div>
  )
}

export default Addlet
