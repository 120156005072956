import React, { useEffect } from 'react'
import { Box } from '@mui/material'

import {
  ComponentModel,
  AppRegistration,
  User,
  Warning,
  isSnackbar,
  isModalModel,
  isAlertModel,
  ResultSubType,
} from '../../../types'
import { CustomComponentHandler } from '../../../store'
import { MissingComponent } from '../MissingComponent/MissingComponent'
import { JuvoAlertContainer } from '../../../containers/JuvoAlert/JuvoAlertContainer'
import { ResultChildrenMapper } from '../../../containers/ResultChildrenMapper/ResultChildrenMapper'
import { convertResultSubTypeToColor } from '../../../utils'
import { useSnackbar } from '../../../providers/SnackbarProvider'
import JuvoResultModal from '../../../containers/JuvoModal/JuvoResultModal'

export const DisplayResultSwitchYard: React.FC<{
  comp: ComponentModel
  appInfo: [Warning, AppRegistration]
  user: User
  customReactComps: CustomComponentHandler
  subType: ResultSubType
  handleDismissCommandAlert: () => void
}> = ({
  comp,
  appInfo,
  user,
  customReactComps,
  subType,
  handleDismissCommandAlert,
}) => {
  const { queueComp } = useSnackbar()

  useEffect(() => {
    if (isSnackbar(comp)) {
      // set the display_state.sub_type if does not exist
      queueComp(comp.sub_type ? comp : { ...comp, sub_type: subType })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comp])

  // avoid MissingComponent when receiving back snackbar components
  if (isSnackbar(comp)) return <></>

  if (isAlertModel(comp)) {
    const children: ComponentModel[] = comp.tchildren || []
    return (
      <JuvoAlertContainer
        severity={convertResultSubTypeToColor(subType)}
        onClose={handleDismissCommandAlert}
        title={comp.title}
      >
        <Box
          sx={{
            padding: '6px 6px 0',
          }}
        >
          <ResultChildrenMapper
            customReactComps={customReactComps}
            appInfo={appInfo}
            user={user}
          >
            {children}
          </ResultChildrenMapper>
        </Box>
      </JuvoAlertContainer>
    )
  }

  if (isModalModel(comp)) {
    return (
      <JuvoResultModal
        component={comp}
        customReactComps={customReactComps}
        appInfo={appInfo}
        user={user}
        subType={subType}
      />
    )
  }

  return <MissingComponent comp={comp} />
}
