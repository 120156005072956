//TODO this may need to be better (server needs to send good ids)

import { alpha, SxProps, Theme } from '@mui/material'
import { sha256 } from 'js-sha256'

import {
  AppRegistration,
  ButtonModel,
  CommandHandler,
  ComponentModel,
  ContentHash,
  ContentHashedComponent,
  getFullComponentType,
  getInputActions,
  InputAction,
  inputActionCmd,
  isButtonsComponent,
  JuvoInfo,
  ListModel,
} from '../types'
import { isDefined, isUndefined } from '../utils/Undefined'
import { logWarn } from '../utils/Logger'
import { DebugInfo, getJuvoConfig } from '../utils/JuvoConfig'
import { genId } from '../utils/Common'

import { validateApp, validateForm, ValidationError } from './Validation'

export const getUniqueId = (component: ComponentModel): string => {
  //NO id can cause issues with updating model on user actions
  if (isDefined(component.type) && isUndefined(component.id)) {
    logWarn('WARNING - no id', { comp: component })
  }
  if (isButtonsComponent(component)) {
    return component.id + '.buttons' //TODO backend:  IDs not passed originally from fill-skeletons
  }
  return `${component.id || getFullComponentType(component)}.${
    component.text || 'undefined-unique-id'
  }`
}

export const getComponentDebugInfo = (component: ComponentModel): string => {
  return `${getFullComponentType(component)}.${component.text}`
}

export const getComponentLabel = (component: ComponentModel): string => {
  return component.text
}

export const getComponentValue = (component: ComponentModel): string => {
  return component.value || ''
}

export const getComponentRecommendation = (
  component: ComponentModel,
): string => {
  return component.recommendation || ''
}

export const setComponentValue =
  <T = string>(component: ComponentModel) =>
  (value: T | null) => {
    return { ...component, value }
  }

export const getDebugInfo = (component: ComponentModel) => {
  return JSON.stringify(component, undefined, 2)
}

export const getJuvoInfo = (
  juvoComponent: string,
  component: ComponentModel,
): JuvoInfo | Record<string, any> => {
  const { showDebugInfo } = getJuvoConfig()
  if (showDebugInfo === DebugInfo.None) {
    return {}
  }

  if (showDebugInfo === DebugInfo.Short) {
    return { 'data-juvo-component': juvoComponent }
  }

  return {
    'data-debug-info': getDebugInfo(component),
    'data-juvo-component': juvoComponent,
    'data-juvo-msg-type': getFullComponentType(component),
    'data-juvo-id': getUniqueId(component), //TODO: server not passing unique ids (e.g. old haskell apps)
  }
}

export const getButtons = (component: ComponentModel): ButtonModel[] => {
  return component.buttons
}

export type EventHandlerMap = {
  // NOTE: we can extend the type with other commonly used handler as needed
  onBlur?: () => void
  onFocus?: () => void
  onKeyUp?: () => void
  onKeyDown?: () => void
  onKeyPress?: () => void
} & {
  [eventName: string]: (...args: any[]) => void
}

export type ComponentEventMap<V> = EventHandlerMap & {
  onChange: (value: V) => void
}

export const getDefinedStyles = (
  component: ComponentModel,
  theme: Theme,
): SxProps<Theme> => {
  const borderStyle = isUndefined(component.render_as)
    ? 'none'
    : `1px solid ${
        component.render_as === 'border-box-success'
          ? theme.palette.success.light
          : theme.palette.grey[300]
      }`

  const backgroundStyle = isUndefined(component.render_as)
    ? 'transparent'
    : component.render_as === 'border-box-success'
      ? alpha(theme.palette.success.light, 0.2)
      : theme.palette.secondary.main

  const paddingStyle = `${(component.padding ?? 0) * 8}px`

  const floatingActionBarStyles =
    component.render_as === 'floating-action-bar'
      ? {
          position: 'sticky',
          top: '-28px',
          left: '0',
          right: '0',
          borderRadius: '4px',
          zIndex: '100',
          boxShadow:
            '0px 5px 3px -1px rgb(0 0 0 / 6%), 0px 9px 10px 0px rgb(0 0 0 / 4%), 0px 6px 12px 0px rgb(0 0 0 / 2%)',
        }
      : {}

  return {
    border: borderStyle,
    background: backgroundStyle,
    padding: paddingStyle,
    ...floatingActionBarStyles,
  }
}

export const mergeOnChangeHandler = <V>(
  events: EventHandlerMap | null,
  onChange: (value: V) => void,
): ComponentEventMap<V> => {
  if (events === null) {
    return { onChange }
  } else if (isDefined(events.onChange)) {
    const onChangeFromServer = events.onChange

    return {
      ...events,
      onChange: e => {
        onChange(e)
        onChangeFromServer(e)
      },
    }
  } else {
    return {
      ...events,
      onChange,
    }
  }
}

export const buttonEvents = (
  comp: ComponentModel | ButtonModel,
  onCommand: CommandHandler,
  performValidation: boolean,
  setValidationErrs: (_: ValidationError[]) => void,
  app: AppRegistration,
  compId: string,
  handlerValidation?: (value: boolean) => void,
): EventHandlerMap | null => {
  const events = componentEvents(() => comp, onCommand)
  if (events === null) {
    return events
  }

  if (isDefined(events.onClick)) {
    const onClickFromServer = events.onClick

    const onBlurFromServer = events.onBlur

    return {
      ...events,
      onClick: e => {
        if (performValidation) {
          let errors: ValidationError[] = []
          // form validation
          const formContainers = findAllFormContainers(
            app.app_skeleton.components,
          )
          const parentForm = findParent(formContainers, compId)
          if (parentForm) {
            errors = validateForm(parentForm)
            handlerValidation && handlerValidation(true)
          } else {
            errors = validateApp(app)
          }
          setValidationErrs(errors)
          if (errors.length === 0) onClickFromServer(e)
        } else {
          onClickFromServer(e)
        }
      },
      onBlur: () => {
        if (isDefined(onBlurFromServer)) {
          onBlurFromServer()
        }
      },
    }
  } else {
    return events
  }
}

const findAllFormContainers = (
  components: ComponentModel[],
  containers: ComponentModel[] = [],
): ComponentModel[] | null => {
  for (const component of components) {
    if (getFullComponentType(component) === 'folder.form') {
      containers.push(component)
    }
    if (component.tchildren) {
      findAllFormContainers(component.tchildren, containers)
    }
  }
  return containers
}

const findParent = (compArray: ComponentModel[] | null, compId: string) => {
  if (!compArray) return null
  for (const item of compArray) {
    if (item.id === compId) {
      return item
    }
    if (item.tchildren) {
      const parent = findParent(item.tchildren, compId)
      if (parent) return item
    }
  }
  return null
}

export const componentEvents = (
  compfn: () => ComponentModel | ButtonModel,
  onCommand: CommandHandler,
): EventHandlerMap | null => {
  const comp = compfn()
  if (Array.isArray(comp.input_actions)) {
    const inputActions: InputAction[] = getInputActions(comp) || []
    const emptyEventHandlerMap: EventHandlerMap = {}

    return inputActions.reduce((acc, inputAction) => {
      // Note: this sets internal loading state on the command if requested by input Action
      acc[`on${inputAction.event}`] = () => {
        if (onCommand.type == 'from-stablestate') {
          const cCmd = inputActionCmd(inputAction, comp)
          onCommand.fn(cCmd, cCmd.component) //there is no easy isButton check
        } else if (onCommand.type == 'from-lastminute-changes') {
          //late bind data from component
          //send message with component specific data
          //that could still be not saved in state (JUVO-844)
          const newcomp = compfn()
          const cCmd = inputActionCmd(inputAction, newcomp)
          onCommand.fn(cCmd, comp)
        }
      }

      return acc
    }, emptyEventHandlerMap)
  } else {
    return null
  }
}

export const mergedComponentEvents = <V>(
  compfn: () => ComponentModel,
  onCommand: CommandHandler,
  onChange: (value: V) => void,
): ComponentEventMap<V> => {
  const msgAttr = componentEvents(compfn, onCommand)

  return mergeOnChangeHandler(msgAttr, onChange)
}

export const userDeleteRecommended =
  (contentHash: ContentHash) =>
  (listcomp: ListModel): ListModel => {
    const newRecommends = listcomp.trecommend
      ? listcomp.trecommend.filter(
          childcomp => childcomp.content_hash !== contentHash,
        )
      : []
    const newTIgnore = listcomp.tignore
      ? listcomp.tignore.indexOf(contentHash) === -1
        ? [...listcomp.tignore, contentHash]
        : listcomp.tignore
      : [contentHash]

    return { ...listcomp, trecommend: newRecommends, tignore: newTIgnore }
  }

export const userAcceptRecommended =
  (contentHash: ContentHash) =>
  (component: ListModel): ListModel => {
    const reccomp = component.trecommend?.find(
      childcomp => childcomp.content_hash === contentHash,
    )
    if (reccomp) {
      const newChildren = [...component.tchildren, reccomp.component]
      const newlist: ListModel = {
        ...component,
        tchildren: newChildren,
        numchildren: newChildren.length,
      }
      return userDeleteRecommended(contentHash)(newlist)
    } else {
      logWarn(
        'list-merge hash not found (duplicate item?), dropping component',
        {
          missingHash: contentHash,
          trecommend: JSON.stringify(component.trecommend),
        },
      )
      return component
    }
  }

/**
 * Used in auto-accepting.  If components are identical (same contentHash)
 * they will still be accepted
 */
export const userAcceptRecommendedBestEffort =
  (recommendedComp: ComponentModel) =>
  (contentHash: ContentHash) =>
  (component: ListModel): ListModel => {
    const reccomp = component.trecommend?.find(
      childcomp => childcomp.content_hash === contentHash,
    )
    if (reccomp) {
      const newChildren = [...component.tchildren, reccomp.component]
      const newlist: ListModel = {
        ...component,
        tchildren: newChildren,
        numchildren: newChildren.length,
      }
      return userDeleteRecommended(contentHash)(newlist)
    } else {
      logWarn(
        'list-merge hash not found (duplicate item?), reinstating component',
        {
          missingHash: contentHash,
          trecommend: JSON.stringify(component.trecommend),
        },
      )
      const newChildren = [...component.tchildren, recommendedComp]
      const newlist: ListModel = {
        ...component,
        tchildren: newChildren,
        numchildren: newChildren.length,
      }
      //the hash has aleady been removed, no need to call userDeleteRecommended
      return newlist
    }
  }

/**
 * Description stub
 * @param component
 */
export const addIdsToCompIfMissing = (
  component: ComponentModel,
): ComponentModel => {
  const res = { ...component }
  if (isUndefined(component.id)) {
    res.id = genId()
  }
  if (Array.isArray(component.tchildren)) {
    res.tchildren = component.tchildren.map(addIdsToCompIfMissing)
  }

  if (isDefined(component.child)) {
    res.child = addIdsToCompIfMissing(component.child)
  }
  return res
}

/**
 * Description stub
 * @param component
 */
export const removeIdsFromComp = (
  component: ComponentModel,
): ComponentModel => {
  const res = { ...component }
  if (isDefined(component.id)) {
    res.id = null
  }
  if (Array.isArray(component.tchildren)) {
    res.tchildren = component.tchildren.map(removeIdsFromComp)
  }

  if (isDefined(component.child)) {
    res.child = removeIdsFromComp(component.child)
  }
  return res
}

export const contentHash = (component: ComponentModel): ContentHash => {
  const sanitized = removeIdsFromComp(component)

  return sha256(JSON.stringify(sanitized))
}

/**
 * Retrieve new recommendations from the message.
 * @param ignoreHashes: hashes that the function ignores
 */
export const msgListRecommendations =
  (ignoreHashes: ContentHash[]) =>
  (messagelist: ListModel): ContentHashedComponent[] => {
    return messagelist.tchildren.reduce((acc, msgcomp) => {
      if (msgcomp.id) return acc
      else {
        const ch = contentHash(msgcomp)
        if (ignoreHashes.indexOf(ch) > -1) {
          return acc
        } else {
          return [
            ...acc,
            { content_hash: ch, component: addIdsToCompIfMissing(msgcomp) },
          ]
        }
      }
    }, [])
  }

export const removeDuplicateHashes = (
  compList: ContentHashedComponent[],
): ContentHashedComponent[] => {
  const result = compList.reduce(
    (acc, hashedcomp) => {
      if (acc.ignoreHashes.indexOf(hashedcomp.content_hash) > -1) {
        logWarn('Removing duplicate hashed component', {
          hashcomp: hashedcomp,
        })
        return acc
      } else {
        const newHashes: string[] = [
          ...acc.ignoreHashes,
          hashedcomp.content_hash,
        ]
        const newRes: ContentHashedComponent[] = [...acc.res, hashedcomp]
        return { ignoreHashes: newHashes, res: newRes }
      }
    },
    { ignoreHashes: [] as string[], res: [] as ContentHashedComponent[] },
  )
  return result.res
}
