import React from 'react'
import Box from '@mui/material/Box'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { Theme } from '@mui/material'

import { StepModel } from '../../../types'

export const generateStyledSteps = (
  activeIndex: number,
  stepModels: StepModel[],
  theme: Theme,
) => {
  return stepModels.map((cp, index) => (
    <Step
      key={index}
      completed={index < activeIndex}
      sx={{
        borderColor: theme =>
          activeIndex === index
            ? theme.palette.primary.main
            : theme.palette.text.secondary,
        borderWidth: activeIndex === index ? '4px' : '2px',
        textAlign: 'center',
        padding: '8px',
      }}
    >
      <StepLabel
        StepIconComponent={() => <></>}
        sx={{
          '.MuiStepLabel-label': {
            fontWeight: `${activeIndex === index ? 700 : 500} !important`,
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            color: theme =>
              `${
                activeIndex >= index
                  ? theme.palette.common.white
                  : theme.palette.grey[400]
              } !important`,
          },
        }}
      >
        <Box
          sx={{
            borderRadius: '100%',
            background: theme =>
              activeIndex >= index
                ? theme.palette.tertiary.main
                : theme.palette.tertiary.light,
            color: theme => theme.palette.common.white,
            width: '40px',
            flexShrink: '0',
            height: '40px',
            display: 'center',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {activeIndex > index ? (
            <i className="fas fa-solid fa-check"></i>
          ) : (
            index + 1
          )}
        </Box>
        {cp.label && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              [theme.breakpoints.down('md')]: {
                display: 'none',
              },
            }}
          >
            <Box
              sx={{
                fontSize: '0.8em',
                fontWeight: 700,
              }}
            >
              Step {index + 1}
            </Box>
            <Box
              sx={{
                textAlign: 'left',
              }}
            >
              {cp.label}
            </Box>
          </Box>
        )}
      </StepLabel>
    </Step>
  ))
}
