import React from 'react'
import { Box } from '@mui/material'

import { FileDropModel, Command } from '../../../types'

import { UploadInput } from './UploadInput'

export const FileDrop: React.FC<{
  comp: FileDropModel
  onCommand: (cmd: Command) => void
  onChange: (_: FileDropModel) => void
}> = ({ comp, onChange, onCommand }) => {
  return (
    <Box
      sx={{
        width: {
          sm: '100%',
          md: '600px',
        },
        marginBottom: '24px',
      }}
    >
      <UploadInput
        compData={comp}
        onCommand={onCommand}
        onChange={onChange}
        accept={comp.accept}
        multiple={comp.multiple}
        text={comp.text}
      />
    </Box>
  )
}
